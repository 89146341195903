import { hrConstants } from '../../constants/hrConstants'

export const payGradeCreateReducer = (state = {}, action) => {
	switch (action.type) {
		case hrConstants.payGradeConstants.payGradeCreate.request:
			return { loading: true }
		case hrConstants.payGradeConstants.payGradeCreate.success:
			return { loading: false, success: true, data: action.payload }
		case hrConstants.payGradeConstants.payGradeCreate.fail:
			return { loading: false, error: action.payload }
		case hrConstants.payGradeConstants.payGradeCreate.reset:
			return {}
		default:
			return state
	}
}
export const payGradeUpdateReducer = (state = {}, action) => {
	switch (action.type) {
		case hrConstants.payGradeConstants.payGradeUpdate.request:
			return { loading: true }
		case hrConstants.payGradeConstants.payGradeUpdate.success:
			return { loading: false, success: true, data: action.payload }
		case hrConstants.payGradeConstants.payGradeUpdate.fail:
			return { loading: false, error: action.payload }
		case hrConstants.payGradeConstants.payGradeUpdate.reset:
			return {}

		default:
			return state
	}
}

export const payGradeDetailsReducer = (
	state = { loading: true, data: [] },
	action,
) => {
	switch (action.type) {
		case hrConstants.payGradeConstants.payGradeDetails.request:
			return { loading: true }
		case hrConstants.payGradeConstants.payGradeDetails.success:
			return { loading: false, success: true, data: action.payload }
		case hrConstants.payGradeConstants.payGradeDetails.fail:
			return { loading: false, error: action.payload }
		default:
			return state
	}
}
export const payGradeDeleteReducer = (state = {}, action) => {
	switch (action.type) {
		case hrConstants.payGradeConstants.payGradeDelete.request:
			return { loading: true }
		case hrConstants.payGradeConstants.payGradeDelete.success:
			return { loading: false, success: true }
		case hrConstants.payGradeConstants.payGradeDelete.fail:
			return { loading: false, error: action.payload }
		case hrConstants.payGradeConstants.payGradeDelete.reset:
			return {}
		default:
			return state
	}
}

export const payGradeListAllReducer = (state = { data: [] }, action) => {
	switch (action.type) {
		case hrConstants.payGradeConstants.payGradeList.request:
			return { loading: true }
		case hrConstants.payGradeConstants.payGradeList.success:
			return { loading: false, success: true, data: action.payload }
		case hrConstants.payGradeConstants.payGradeList.fail:
			return { loading: false, error: action.payload }
		default:
			return state
	}
}
