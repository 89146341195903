// phases.js
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getPhases } from '../../../redux/actions'
import PhaseList from './phase-list'
import Sidebar from '../../../components/Navigation/sidebar2'
import { Header2, HomePageLinks } from '../../../components'

const Phases = () => {
	const dispatch = useDispatch()
	const phasesRdx = useSelector((state) => state.phases)

	const [phases, setPhases] = useState([])

	useEffect(() => {
		dispatch(getPhases())

		return () => {}
	}, [dispatch])

	useEffect(() => {
		if (phasesRdx && phasesRdx.data) {
			setPhases(phasesRdx.data)
		}

		if (phasesRdx && phasesRdx.error) {
			setPhases(['Error loading phases...'])
		}

		return () => {}
	}, [phasesRdx.data, phasesRdx.error, phasesRdx])

	return (
		<div className='dark:bg-main-dark-bg bg-main-bg dark:text-romanLight1 font-opensans mt-20'>
			<Sidebar />
			<Header2 links={<HomePageLinks />} />
			<div className='ml-4 '>
				<PhaseList
					// FOR TESTING DEVELOPMENT
					// phases={[
					// 	{
					// 		phase: 'Appointment Set',
					// 		group: 'Inside Sales',
					// 		department: 'Sales',

					// 		order: 1,
					// 	},
					// ]}
					// phases={[]}
					// phases={['Error loading phases...']}
					phases={phases}
				/>
			</div>
		</div>
	)
}

export default Phases
