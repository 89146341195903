import { REDUX_CONST } from '../../constants'

export const getEvents = (state = { data: [] }, action) => {
	switch (action.type) {
		case REDUX_CONST.giddyUp.eventConstants.getEvents.request:
			return { loading: true }
		case REDUX_CONST.giddyUp.eventConstants.getEvents.success:
			return { data: action.payload }
		case REDUX_CONST.giddyUp.eventConstants.getEvents.fail:
			return { error: action.payload }
		default:
			return state
	}
}

export const getLeads = (
	state = {
		leads_last7Days: [],
		leads_last30Days: [],
		leads_last90Days: [],
		leads_last180Days: [],
	},
	action,
) => {
	switch (action.type) {
		case REDUX_CONST.giddyUp.eventConstants.getLeads.request:
			return { ...state, loading: true }
		case REDUX_CONST.giddyUp.eventConstants.getLeads.success:
			const { key, leads } = action.payload
			return {
				...state,
				loading: false,
				[`leads_${key}`]: leads,
			}
		case REDUX_CONST.giddyUp.eventConstants.getLeads.fail:
			return {
				...state,
				loading: false,
				error: action.payload,
			}
		default:
			return state
	}
}
export const getSales = (
	state = {
		sales_last7Days: [],
		sales_last30Days: [],
		sales_last90Days: [],
		sales_last180Days: [],
	},
	action,
) => {
	switch (action.type) {
		case REDUX_CONST.giddyUp.eventConstants.getSales.request:
			return { ...state, loading: true }
		case REDUX_CONST.giddyUp.eventConstants.getSales.success:
			const { key, sales } = action.payload
			return {
				...state,
				loading: false,
				[`sales_${key}`]: sales,
			}
		case REDUX_CONST.giddyUp.eventConstants.getSales.fail:
			return {
				...state,
				loading: false,
				error: action.payload,
			}
		default:
			return state
	}
}
