export const PAYGRADE_CREATE_REQUEST = 'PAYGRADE_CREATE_REQUEST'
export const PAYGRADE_CREATE_SUCCESS = 'PAYGRADE_CREATE_SUCCESS'
export const PAYGRADE_CREATE_FAIL = 'PAYGRADE_CREATE_FAIL'
export const PAYGRADE_CREATE_RESET = 'PAYGRADE_CREATE_RESET'

export const PAYGRADE_DETAILS_REQUEST = 'PAYGRADE_DETAILS_REQUEST'
export const PAYGRADE_DETAILS_SUCCESS = 'PAYGRADE_DETAILS_SUCCESS'
export const PAYGRADE_DETAILS_FAIL = 'PAYGRADE_DETAILS_FAIL'

export const PAYGRADE_LIST_ALL_REQUEST = 'PAYGRADE_LIST_ALL_REQUEST'
export const PAYGRADE_LIST_ALL_SUCCESS = 'PAYGRADE_LIST_ALL_SUCCESS'
export const PAYGRADE_LIST_ALL_FAIL = 'PAYGRADE_LIST_ALL_FAIL'

export const PAYGRADE_UPDATE_REQUEST = 'PAYGRADE_UPDATE_REQUEST'
export const PAYGRADE_UPDATE_SUCCESS = 'PAYGRADE_UPDATE_SUCCESS'
export const PAYGRADE_UPDATE_FAIL = 'PAYGRADE_UPDATE_FAIL'
export const PAYGRADE_UPDATE_RESET = 'PAYGRADE_UPDATE_RESET'

export const PAYGRADE_DELETE_REQUEST = 'PAYGRADE_DELETE_REQUEST'
export const PAYGRADE_DELETE_SUCCESS = 'PAYGRADE_DELETE_SUCCESS'
export const PAYGRADE_DELETE_FAIL = 'PAYGRADE_DELETE_FAIL'
export const PAYGRADE_DELETE_RESET = 'PAYGRADE_DELETE_RESET'

export const payGradeConstants = {
	payGradeCreate: {
		request: PAYGRADE_CREATE_REQUEST,
		success: PAYGRADE_CREATE_SUCCESS,
		fail: PAYGRADE_CREATE_FAIL,
		reset: PAYGRADE_CREATE_RESET,
	},
	payGradeDelete: {
		request: PAYGRADE_DELETE_REQUEST,
		success: PAYGRADE_DELETE_SUCCESS,
		fail: PAYGRADE_DELETE_FAIL,
		reset: PAYGRADE_DELETE_RESET,
	},
	payGradeDetails: {
		request: PAYGRADE_DETAILS_REQUEST,
		success: PAYGRADE_DETAILS_SUCCESS,
		fail: PAYGRADE_DETAILS_FAIL,
	},
	payGradeList: {
		request: PAYGRADE_LIST_ALL_REQUEST,
		success: PAYGRADE_LIST_ALL_SUCCESS,
		fail: PAYGRADE_LIST_ALL_FAIL,
	},
	payGradeUpdate: {
		request: PAYGRADE_UPDATE_REQUEST,
		success: PAYGRADE_UPDATE_SUCCESS,
		fail: PAYGRADE_UPDATE_FAIL,
		reset: PAYGRADE_UPDATE_RESET,
	},
}
