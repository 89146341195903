import React from 'react'
import './Message.css'

const Message = (props) => {
	const { children, title, variant, titleClass, childClass, wrapperClass } =
		props

	return (
		<div className={`${variant} ${wrapperClass}`}>
			<div className={' mb-2 '}>
				<p className={titleClass}>{title}</p>
				<p className={childClass}>{children}</p>
			</div>
		</div>
	)
}

Message.defaultProps = {
	variant: 'info',
	wrapperClass: 'm-2 md:m-10  py-5 md:px-10 rounded-r-xl',
	titleClass: 'text-xl font-bold ',
	childClass: 'text-lg ',
}

export default Message
