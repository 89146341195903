import React from 'react'

const Backdrop = (props) => {
	const { children, backgroundRGB, z, onClick } = props

	return (
		<div
			style={{
				position: 'fixed',
				top: '0',
				left: '0',
				width: '100%',
				height: '100vh',
				zIndex: z ? z : '10',
				background: backgroundRGB ? backgroundRGB : 'rgba(0, 0, 0, 0.85)',
			}}
			onClick={onClick ? onClick : undefined}
		>
			{children}
		</div>
	)
}

export default Backdrop
