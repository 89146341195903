import {
	KPI_CREATE_REQUEST,
	KPI_CREATE_SUCCESS,
	KPI_CREATE_FAIL,
	KPI_CREATE_RESET,
	KPI_DETAILS_REQUEST,
	KPI_DETAILS_SUCCESS,
	KPI_DETAILS_FAIL,
	KPI_LIST_USER_REQUEST,
	KPI_LIST_USER_SUCCESS,
	KPI_LIST_USER_FAIL,
	KPI_LIST_USER_RESET,
	KPI_LIST_DATESTRING_REQUEST,
	KPI_LIST_DATESTRING_SUCCESS,
	KPI_LIST_DATESTRING_FAIL,
	KPI_LIST_DATESTRING_RESET,
	KPI_LIST_ALL_FAIL,
	KPI_LIST_ALL_SUCCESS,
	KPI_LIST_ALL_REQUEST,
} from '../constants/kpiConstants'

export const kpiCreateReducer = (state = {}, action) => {
	switch (action.type) {
		case KPI_CREATE_REQUEST:
			return {
				loading: true,
			}
		case KPI_CREATE_SUCCESS:
			return {
				loading: false,
				success: true,
				kpi: action.payload,
			}
		case KPI_CREATE_FAIL:
			return {
				loading: false,
				error: action.payload,
			}
		case KPI_CREATE_RESET:
			return {}
		default:
			return state
	}
}

export const kpiDetailsReducer = (
	state = { loading: true, kpiItems: [] },
	action,
) => {
	switch (action.type) {
		case KPI_DETAILS_REQUEST:
			return {
				...state,
				loading: true,
			}
		case KPI_DETAILS_SUCCESS:
			return {
				loading: false,
				kpi: action.payload,
			}
		case KPI_DETAILS_FAIL:
			return {
				loading: false,
				error: action.payload,
			}
		default:
			return state
	}
}

export const kpiListUserReducer = (state = { kpis: [] }, action) => {
	switch (action.type) {
		case KPI_LIST_USER_REQUEST:
			return {
				loading: true,
			}
		case KPI_LIST_USER_SUCCESS:
			return {
				loading: false,
				kpis: action.payload,
			}
		case KPI_LIST_USER_FAIL:
			return {
				loading: false,
				error: action.payload,
			}
		case KPI_LIST_USER_RESET:
			return { kpis: [] }
		default:
			return state
	}
}

export const kpiListAllReducer = (state = { kpis: [] }, action) => {
	switch (action.type) {
		case KPI_LIST_ALL_REQUEST:
			return {
				loading: true,
			}
		case KPI_LIST_ALL_SUCCESS:
			return {
				loading: false,
				kpis: action.payload,
			}
		case KPI_LIST_ALL_FAIL:
			return {
				loading: false,
				error: action.payload,
			}
		default:
			return state
	}
}

export const kpiListDateStringReducer = (state = { kpis: [] }, action) => {
	switch (action.type) {
		case KPI_LIST_DATESTRING_REQUEST:
			return {
				loading: true,
			}
		case KPI_LIST_DATESTRING_SUCCESS:
			return {
				loading: false,
				kpis: action.payload,
			}
		case KPI_LIST_DATESTRING_FAIL:
			return {
				loading: false,
				error: action.payload,
			}
		case KPI_LIST_DATESTRING_RESET:
			return { kpis: [] }
		default:
			return state
	}
}
