import { REDUX_CONST } from '../../constants'

export const getJobs = (state = { data: [] }, action) => {
	switch (action.type) {
		case REDUX_CONST.giddyUp.jobConstants.getJobs.request:
			return { loading: true }
		case REDUX_CONST.giddyUp.jobConstants.getJobs.success:
			return { data: action.payload }
		case REDUX_CONST.giddyUp.jobConstants.getJobs.fail:
			return { error: action.payload }
		default:
			return state
	}
}
