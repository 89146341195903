import React from 'react'

const Cell = (props) => {
	const {
		width,
		color,
		height,
		borderColor,
		borderStyle,
		padding,
		margin,
		children,
		className,
	} = props
	return (
		<div
			className={`${width} ${height} ${color} ${borderStyle} ${borderColor} ${padding} ${margin} ${className}`}
		>
			{children}
		</div>
	)
}

export default Cell
