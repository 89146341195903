//summary-donut-chart.js
import React from 'react'

import {
	PieChart,
	Pie,
	Cell,
	Tooltip,
	Legend,
	ResponsiveContainer,
} from 'recharts'
import { CONST_COLORS } from '../../../../constants/general'

const COLORS = [CONST_COLORS.romanGold1, CONST_COLORS.romanRed1]

const renderCustomizedLabel = ({
	cx,
	cy,
	midAngle,
	innerRadius,
	outerRadius,
	percent,
	index,
	payload,
}) => {
	const RADIAN = Math.PI / 180
	const radius = innerRadius + (outerRadius - innerRadius) * 0.5
	const x = cx + radius * Math.cos(-midAngle * RADIAN)
	const y = cy + radius * Math.sin(-midAngle * RADIAN)

	return (
		<text
			className='text-xs  font-semibold'
			x={x}
			y={y}
			textAnchor='middle'
			dominantBaseline='central'
			fill='white'
		>
			{`${(percent * 100).toFixed(0)}%`}
		</text>
	)
}
const CustomTooltip = ({ active, payload }) => {
	if (active && payload && payload.length) {
		return (
			<div className='p-2 bg-romanLight3 dark:bg-romanDark3 bg-opacity-90 dark:bg-opacity-90'>
				{payload.map((entry, index) => (
					<p
						style={{ color: entry.color }}
						key={`item-${index}`}
					>{`${entry.name} : ${entry.value}`}</p>
				))}
			</div>
		)
	}
	return null
}

const SummaryDonutChart = ({ data }) => (
	<ResponsiveContainer width='100%' height={300}>
		<PieChart>
			<Pie
				data={data}
				cx='50%'
				cy='50%'
				labelLine={false}
				label={renderCustomizedLabel}
				outerRadius={80}
				innerRadius={40}
				fill='#8884d8'
				dataKey='value'
			>
				{data.map((entry, index) => (
					<Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
				))}
			</Pie>
			<Tooltip content={<CustomTooltip />} />

			<Legend />
		</PieChart>
	</ResponsiveContainer>
)

export default SummaryDonutChart
