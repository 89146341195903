export const JOBS_LIST_ALL_REQUEST = 'JOBS_LIST_ALL_REQUEST'
export const JOBS_LIST_ALL_SUCCESS = 'JOBS_LIST_ALL_SUCCESS'
export const JOBS_LIST_ALL_FAIL = 'JOBS_LIST_ALL_FAIL'

export const JOB_CREATE_REQUEST = 'JOB_CREATE_REQUEST'
export const JOB_CREATE_SUCCESS = 'JOB_CREATE_SUCCESS'
export const JOB_CREATE_FAIL = 'JOB_CREATE_FAIL'
export const JOB_CREATE_RESET = 'JOB_CREATE_RESET'

export const JOB_DELETE_REQUEST = 'JOB_DELETE_REQUEST'
export const JOB_DELETE_SUCCESS = 'JOB_DELETE_SUCCESS'
export const JOB_DELETE_FAIL = 'JOB_DELETE_FAIL'
export const JOB_DELETE_RESET = 'JOB_DELETE_RESET'

export const JOB_UPDATE_REQUEST = 'JOB_UPDATE_REQUEST'
export const JOB_UPDATE_SUCCESS = 'JOB_UPDATE_SUCCESS'
export const JOB_UPDATE_FAIL = 'JOB_UPDATE_FAIL'
export const JOB_UPDATE_RESET = 'JOB_UPDATE_RESET'

export const jobConstants = {
	getJobs: {
		request: JOBS_LIST_ALL_REQUEST,
		success: JOBS_LIST_ALL_SUCCESS,
		fail: JOBS_LIST_ALL_FAIL,
	},

	updateJob: {
		request: JOB_UPDATE_REQUEST,
		success: JOB_UPDATE_SUCCESS,
		fail: JOB_UPDATE_FAIL,
		reset: JOB_UPDATE_RESET,
	},

	deleteJob: {
		request: JOB_DELETE_REQUEST,
		success: JOB_DELETE_SUCCESS,
		fail: JOB_DELETE_FAIL,
		reset: JOB_DELETE_RESET,
	},
	createJob: {
		request: JOB_CREATE_REQUEST,
		success: JOB_CREATE_SUCCESS,
		fail: JOB_CREATE_FAIL,
		reset: JOB_CREATE_RESET,
	},
}
