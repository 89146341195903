import React from 'react'

const Row = (props) => {
	const { className, children, addClassName } = props

	return (
		<div
			className={
				className
					? className
					: addClassName
					? `flex justify-between ${addClassName} `
					: 'flex justify-between'
			}
		>
			{children}
		</div>
	)
}

export default Row
