// events-summary.js
import React, { useState } from 'react'
import ChartComponent from './chart-component'
import { CONST_DATES } from '../../../constants/general'

const EventsSummary = ({ events }) => {
	const [phaseChangeDateFilter, setPhaseChangeDateFilter] = useState(
		CONST_DATES.all,
	)
	const [eventDateFilter, setEventDateFilter] = useState(CONST_DATES.all)

	const filterEvents = (events, dateFilter) => {
		const now = new Date()
		return events.filter((event) => {
			const eventDate = new Date(event.timeStamp)
			switch (dateFilter) {
				case CONST_DATES.today:
					return eventDate.toDateString() === now.toDateString()
				case CONST_DATES.thisWeek:
					const oneWeekAgo = new Date()
					oneWeekAgo.setDate(now.getDate() - 7)
					return eventDate > oneWeekAgo
				case CONST_DATES.thisMonth:
					return (
						eventDate.getMonth() === now.getMonth() &&
						eventDate.getFullYear() === now.getFullYear()
					)
				default:
					return true // return all events if dateFilter is CONST_DATES.all
			}
		})
	}

	const formatText = (camelBackString) => {
		const words = camelBackString.split(/(?=[A-Z])/)
		const formattedString = words
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ')
		return formattedString
	}

	// Separate events into two datasets: Events and Phase Changes
	const separateEvents = events.reduce(
		(acc, event) => {
			if (event.eventType === 'phaseChange') {
				acc.phaseChanges.push(event)
			} else {
				acc.events.push(event)
			}
			return acc
		},
		{ events: [], phaseChanges: [] },
	)

	// Now filter the separated events and phase changes
	const filteredEventData = filterEvents(
		separateEvents.events,
		eventDateFilter,
	).reduce((acc, event) => {
		acc[event.eventType] = (acc[event.eventType] || 0) + 1
		return acc
	}, {})

	const filteredPhaseChangeData = filterEvents(
		separateEvents.phaseChanges,
		phaseChangeDateFilter,
	).reduce((acc, event) => {
		acc[event.phase] = (acc[event.phase] || 0) + 1
		return acc
	}, {})

	// Convert to an array of objects suitable for recharts
	const eventData = Object.keys(filteredEventData).map((eventType) => ({
		name: formatText(eventType),
		Count: filteredEventData[eventType],
	}))

	const phaseChangeData = Object.keys(filteredPhaseChangeData).map((phase) => ({
		name: formatText(phase),
		Count: filteredPhaseChangeData[phase],
	}))

	const phaseChangeDateFilterHandler = (filter) => {
		if (filter === phaseChangeDateFilter) {
			setPhaseChangeDateFilter(CONST_DATES.all)
		} else {
			setPhaseChangeDateFilter(filter)
		}
	}

	const eventDateFilterHandler = (filter) => {
		if (filter === eventDateFilter) {
			setEventDateFilter(CONST_DATES.all)
		} else {
			setEventDateFilter(filter)
		}
	}

	return (
		<div className='flex flex-col items-center space-y-10 text-xs w-full'>
			<ChartComponent
				filter={eventDateFilter}
				data={eventData}
				title='Event Data'
				onDateFilter={eventDateFilterHandler}
			/>
			<ChartComponent
				filter={phaseChangeDateFilter}
				data={phaseChangeData}
				title='Phase Change Data'
				onDateFilter={phaseChangeDateFilterHandler}
			/>
		</div>
	)
}

export default EventsSummary
