// summary-financials.js
import React from 'react'

const SummaryFinancials = () => {
	const financialData = {
		Revenue: {
			YTD: 500000, // Example number
			LTM: 900000, // Example number
		},
		'Gross Margin': {
			YTD: 250000, // Example number
			LTM: 400000, // Example number
		},
		EBITDA: {
			YTD: 200000, // Example number
			LTM: 350000, // Example number
		},
	}

	return (
		<div className='grid grid-cols-5 min-w-[400px]  gap-4 p-4 bg-romanLight1 dark:bg-romanDark3  rounded-md'>
			<div key='metric-header' className='font-bold'>
				Metrics
			</div>
			<div key='ytd-header' className='font-bold text-center'>
				YTD
			</div>
			<div key='ltm-header' className='font-bold text-center'>
				LTM
			</div>
			<div key='percent-sales-ytd-header' className='font-bold text-center'>
				% of Sales (YTD)
			</div>
			<div key='percent-sales-ltm-header' className='font-bold text-center'>
				% of Sales (LTM)
			</div>

			{Object.entries(financialData).map(([key, value]) => (
				<React.Fragment key={key}>
					<div className='py-2'>{key}</div>
					<div className='text-center'>${value.YTD.toLocaleString()}</div>
					<div className='text-center'>${value.LTM.toLocaleString()}</div>
					<div className='text-center'>
						{((value.YTD * 100) / financialData.Revenue.YTD).toFixed(2)}%
					</div>
					<div className='text-center'>
						{((value.LTM * 100) / financialData.Revenue.LTM).toFixed(2)}%
					</div>
				</React.Fragment>
			))}
		</div>
	)
}

export default SummaryFinancials
