import React from 'react'

const Metric4 = () => {
	return (
		<div className='p-10 rounded-xl text-gray-900 bg-white'>
			{/* <!-- Box Header --> */}
			<div className='flex space-x-4'>
				<div className='w-10 h-10 rounded-full ring-2 ring-purple-300'></div>
				<div className='text-sm'>
					<h4 className='opacity-90'>Bottom Left</h4>
					<p className='opacity-50'>SubTitle</p>
				</div>
			</div>

			{/* <!-- Large Text --> */}
			<p className='mt-6 text-xl'>TITLE</p>

			{/* <!-- Small Text --> */}
			<p className='mt-6 opacity-50'>TEXT</p>
		</div>
	)
}

export default Metric4
