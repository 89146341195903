//long-trend.js
import React from 'react'
import GridWrapper from '../../grid-wrapper'

const LongTrend = ({ eventTypes }) => {
	return (
		<GridWrapper
			firstLine={'Table'}
			secondLine={'Title'}
			colSpan={' md:col-span-4 '}
		>
			Content
		</GridWrapper>
	)
}

export default LongTrend
