import React from 'react'
import { Footer2, Header2, HomePageLinks } from '../../../components'
import MetricGrid from './MetricGrid'

const AdminScorecard = () => {
	return (
		<div className='dark:bg-main-dark-bg  bg-main-bg dark:text-white font-opensans mt-20'>
			<Header2 links={<HomePageLinks />} />

			<div className='dark:text-white text-xl md:text-3xl text-center justify-center items-center p-4'>
				Admin Metrics
			</div>

			<MetricGrid />

			<Footer2 />
		</div>
	)
}

export default AdminScorecard
