import React from 'react'

import { useStateContext } from '../../contexts/ContextProvider'
import { Loader } from '../'

const Button = ({
	type,
	padding,
	noShadow,
	loading,
	loaderClass,
	disabled,
	icon,
	bgColor,
	color,
	bgHoverColor,
	size,
	text,
	borderRadius,
	width,
	customFunc,
	gapOverride,
	leaveModalOpen,
	addClassName,
}) => {
	const { setIsClicked, initialState } = useStateContext()

	const clickHandler = (e) => {
		customFunc && customFunc(e)

		!leaveModalOpen && setIsClicked(initialState)
	}

	const disabledClassName = ` text-${size ? size : 'md'} p-${
		padding ? padding : '3'
	} w-${width} focus:outline-none `
	const className = ` ${disabledClassName} ${
		icon && text
			? ` flex items-center ${gapOverride ? gapOverride : 'gap-5'} `
			: ''
	} 
	${noShadow ? '' : ' shadow-md '} hover:opacity-90 hover:bg-${bgHoverColor}`

	return (
		<button
			disabled={disabled}
			type={type || 'button'}
			onClick={type ? undefined : clickHandler}
			style={{ backgroundColor: bgColor, color, borderRadius }}
			className={
				disabled
					? `${disabledClassName} ${addClassName}`
					: `${className} ${addClassName}`
			}
		>
			{!loading && icon} {!loading && text}
			{loading && <Loader className={loaderClass ? loaderClass : ' '} />}
		</button>
	)
}

export default Button
