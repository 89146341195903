import React from 'react'

const Card = (props) => {
	const {
		category,
		title,
		width,
		categoryClasses,
		titleClasses,
		children,
		injectedElement,
		injectedButton,
		wrapperStyle,
		className,
		headerClass,
	} = props

	return (
		<div
			className={`${width} ${
				className
					? className
					: 'border-1 border-gray-300 shadow-md bg-white dark:text-gray-200 dark:bg-secondary-dark-bg m-2 md:m-10 mt-1 p-2 md:p-10 rounded-theme-rounding'
			}`}
			style={wrapperStyle}
		>
			<div className={headerClass ? headerClass : 'mb-10 flex justify-between'}>
				<div>
					<div className={categoryClasses}>{category}</div>
					<div className={titleClasses}>{title}</div>
				</div>
				<div className='flex gap-4'>
					{injectedButton}
					{injectedElement}
				</div>
			</div>

			{children}
		</div>
	)
}
export default Card
