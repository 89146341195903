//Header2.js
import React from 'react'

const Header2 = ({ links }) => {
	return (
		<header className=' mx-auto mt-10 px-6  dark:text-romanLight2 text-center h-40 md:h-20'>
			{/* <!-- Dynamic Logo --> */}
			{/* <div className='bg-logo-light-mode dark:bg-logo-dark-mode bg-no-repeat h-20 w-48 mx-auto md:mx-0 md:absolute top-10 left-10'></div> */}

			<img
				src='/images/RomanHeadLogo.png'
				alt=''
				className='hidden md:block w-32 mx-auto md:mx-0 md:absolute top-5 left-10'
			/>

			{/* <!-- Menu --> */}
			<div className='flex items-center flex-wrap justify-end space-x-4 md:space-x-10 md:absolute top-12 left-52 right-10'>
				{links && links}
			</div>
		</header>
	)
}

export default Header2
