// BACKEND API ROUTES (EXPRESS AND ELECTRON)

//MAIN CHANNEL  FOR ELECTRON
export const API_COMMUNICATIONS = 'API_COMMUNICATIONS'
export const MAIN_COMMUNICATIONS = 'MAIN_COMMUNICATIONS'

//CHANNELS (Beginnings of express api routes :: Electron channels)
export const API_CHANNEL_USERS = '/api/users'
export const API_CHANNEL_HR = '/api/hr'
export const API_CHANNEL_BAMBOOHR = '/api/bamboohr'
export const API_CHANNEL_MSSQL = '/api/mssql'
export const API_CHANNEL_AUX = '/api/auxillary-communications'
export const API_CHANNEL_GIDDY_UP = '/api/giddyUp'
export const API_CHANNEL_DEPARTMENTS = '/api/departments'

//ROUTES
// exports.API_ROUTE_LOGIN = 'login'
export const API_ROUTE_LOGIN = '/login1'
export const API_ROUTE_PROFILE = '/profile'
export const API_ROUTE_LEVELS = '/levels'
export const API_ROUTE_PAYGRADES = '/paygrades'
export const API_ROUTE_POSITIONS = '/positions'
export const API_ROUTE_DEPARTMENTS = '/departments'
export const API_ROUTE_EMPLOYEES = '/employees'
export const API_ROUTE_DIRECTORY = '/directory'

export const API_ROUTE_GET_EVENTS = '/get-events'
export const API_ROUTE_GET_LEADS = '/get-leads'
export const API_ROUTE_GET_SALES = '/get-sales'
export const API_ROUTE_GET_JOBS = '/get-jobs'
export const API_ROUTE_GET_PHASES = '/get-phases'

export const CONST_ROMAN_KPI = {
	nonDigitalLeadGenerated: {
		eventType: 'nonDigitalLeadGenerated',
		alias: 'nonDigitalLeadCreated',
		chartName: 'Lead Created',
		department: 'Marketing',
	},
	nonDigitalLeadCreated: {
		eventType: 'nonDigitalLeadCreated',
		alias: 'nonDigitalLeadCreated',
		chartName: 'Lead Created',
		department: 'Marketing',
	},
	digitalLeadGenerated: {
		eventType: 'digitalLeadGenerated',
		alias: 'digitalLeadGenerated',
		chartName: 'Digital Lead',
		department: 'Marketing',
	},
	salesmanLeadCreated: {
		eventType: 'salesmanLeadCreated',
		alias: 'salesmanLeadCreated',
		chartName: 'Salesman Lead',
		department: 'Marketing',
	},

	leftVoicemail: {
		eventType: 'leftVoicemail',
		alias: 'leftVoicemail',
		chartName: 'Voicemail',
		department: 'Customer Care',
	},
	phoneCall: {
		eventType: 'phoneCall',
		alias: 'phoneCall',
		chartName: 'Phone Call',
		department: 'Customer Care',
	},
	phoneCallMade: {
		eventType: 'phoneCallMade',
		alias: 'phoneCall',
		chartName: 'Phone Call',
		department: 'Customer Care',
	},
	internalAudit: {
		eventType: 'internalAudit',
		alias: 'internalAudit',
		chartName: 'Internal Audit Entered?',
		department: 'Pre-Production',
	},
	jobFileAudited: {
		eventType: 'jobFileAudited',
		alias: 'jobFileAudited',
		chartName: 'File Audited',
		department: 'Pre-Production',
	},
	permitApplied: {
		eventType: 'permitApplied',
		alias: 'permitApplied',
		chartName: 'Applied for Permit',
		department: 'Pre-Production',
	},
	permitReceived: {
		eventType: 'permitReceived',
		alias: 'permitReceived',
		chartName: 'Permit Received',
		department: 'Pre-Production',
	},
	waitingForPermitting: {
		eventType: 'waitingForPermitting',
		alias: 'waitingForPermitting',
		chartName: 'Waiting For Permitting?',
		department: 'Pre-Production',
	},
	jobCompleted: {
		eventType: 'jobCompleted',
		alias: 'jobCompleted',
		chartName: 'Job Completed',
		department: 'Production',
	},
	jobScheduled: {
		eventType: 'jobScheduled',
		alias: 'jobScheduled',
		chartName: 'Job Scheduled',
		department: 'Production',
	},
	repairComplete: {
		eventType: 'repairComplete',
		alias: 'repairComplete',
		chartName: 'Repair Complete',
		department: 'Production',
	},
	repairScheduled: {
		eventType: 'repairScheduled',
		alias: 'repairScheduled',
		chartName: 'Repair Scheduled',
		department: 'Production',
	},
	appointmentSet: {
		eventType: 'appointmentSet',
		alias: 'appointmentSet',
		chartName: 'Appointment Set',
		department: 'Sales',
	},
	contractSigned: {
		eventType: 'contractSigned',
		alias: 'contractSigned',
		chartName: 'Contract Signed',
		department: 'Sales',
	},
	estimateSent: {
		eventType: 'estimateSent',
		alias: 'estimateSent',
		chartName: 'Estimate Sent',
		department: 'Sales',
	},
	initialAppointmentCompleted: {
		eventType: 'initialAppointmentCompleted',
		alias: 'initialAppointmentCompleted',
		chartName: 'Initial Appt Completed',
		department: 'Sales',
	},
	leadContactAttempted: {
		eventType: 'leadContactAttempted',
		alias: 'leadContactAttempted',
		chartName: 'Lead Contacted',
		department: 'Sales',
	},
	leadEmailAttempted: {
		eventType: 'leadEmailAttempted',
		alias: 'leadEmailAttempted',
		chartName: 'Lead Emailed',
		department: 'Sales',
	},
	leadPhoneCallAttempted: {
		eventType: 'leadPhoneCallAttempted',
		alias: 'leadPhoneCallAttempted',
		chartName: 'Lead Phoned',
		department: 'Sales',
	},
	solarSold: {
		eventType: 'solarSold',
		alias: 'solarSold',
		chartName: 'Solar Job Sold',
		department: 'Sales',
	},
	submittedToAudit: {
		eventType: 'submittedToAudit',
		alias: 'submittedToAudit',
		chartName: 'File Submitted To Audit',
		department: 'Sales',
	},
}

export const API_CONST = {
	channels: {
		users: API_CHANNEL_USERS,
		hr: API_CHANNEL_HR,
		bambooHR: API_CHANNEL_BAMBOOHR,
		giddyUp: API_CHANNEL_GIDDY_UP,
		departments: API_CHANNEL_DEPARTMENTS,
	},
	routes: {
		home: '/',
		users: {
			login: API_ROUTE_LOGIN,
			profile: API_ROUTE_PROFILE,
		},
		hr: {
			levels: API_ROUTE_LEVELS,
			payGrades: API_ROUTE_PAYGRADES,
			positions: API_ROUTE_POSITIONS,
			departments: API_ROUTE_DEPARTMENTS,
			employees: API_ROUTE_EMPLOYEES,
		},
		bambooHR: {
			directory: API_ROUTE_DIRECTORY,
		},
		giddyUp: {
			phases: {
				getPhases: API_ROUTE_GET_PHASES,
			},
			events: {
				getEvents: API_ROUTE_GET_EVENTS,
				getLeads: API_ROUTE_GET_LEADS,
				getSales: API_ROUTE_GET_SALES,
			},
			jobs: {
				getJobs: API_ROUTE_GET_JOBS,
			},
		},
	},
}
