// exports.UPDATE_COLOR_THEME_SETTING_REQUEST =
// 	'UPDATE_COLOR_THEME_SETTING_REQUEST'
// exports.UPDATE_COLOR_THEME_SETTING_SUCCESS =
// 	'UPDATE_COLOR_THEME_SETTING_SUCCESS'
// exports.UPDATE_COLOR_THEME_SETTING_FAIL = 'UPDATE_COLOR_THEME_SETTING_FAIL'

exports.settingsConstants = {
	// updateColorTheme: {
	// 	request: this.UPDATE_COLOR_THEME_SETTING_REQUEST,
	// 	success: this.UPDATE_COLOR_THEME_SETTING_SUCCESS,
	// 	fail: this.UPDATE_COLOR_THEME_SETTING_FAIL,
	// },
}
