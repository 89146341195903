import React from 'react'
import { Card, Backdrop } from '../'
import { useStateContext } from '../../contexts/ContextProvider'

const ContextMenu = (props) => {
	const {
		width,
		children,
		category,
		title,
		titleClass,
		categoryClass,
		injectedButton,
		saveButton,
		saveButtonClass,
		headerClass,
		onSave,
		lastEvent,
		xOffset,
		yOffset,
	} = props

	const { setIsClicked, initialState } = useStateContext()

	const closeContextHandler = () => setIsClicked({ ...initialState })

	return (
		<>
			<Backdrop onClick={closeContextHandler} backgroundRGB={'rgba(0,0,0,0)'} />
			<div
				className={`absolute z-50 `}
				style={
					lastEvent && {
						top: yOffset ? lastEvent.pageY + yOffset : lastEvent.pageY,
						left: xOffset ? lastEvent.pageX + xOffset : lastEvent.pageX,
					}
				}
			>
				{/* {lastPayload && lastPayload} */}

				<div className='flex-wrap'>
					<Card
						width={width}
						category={category}
						title={title}
						categoryClasses={categoryClass}
						titleClasses={titleClass}
						injectedButton={injectedButton}
						headerClass={headerClass}
						className={
							'border-1 border-romanLightBorder1 dark:border-romanDarkBorder1 shadow-md bg-romanLight1 dark:bg-romanDark1 text-romanLightTextActive dark:text-romanDarkTextActive  p-2 md:p-4 rounded-theme-rounding'
						}
					>
						<form onSubmit={onSave}>
							{children}

							{saveButton && (
								<div className={saveButtonClass}>{saveButton}</div>
							)}
						</form>
					</Card>
				</div>
			</div>
		</>
	)
}

export default ContextMenu
