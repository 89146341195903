//bottom-table.js
import React, { useEffect, useState } from 'react'
import { CONST_MONGO_DB_FIELDS } from '../../../../constants/general'
import { CONST_ROMAN_KPI } from '../../../../constants/api'

const BottomTable = ({ rawData, eventTypeColors }) => {
	const [tableData, setTableData] = useState([])

	useEffect(() => {
		if (rawData && rawData.length > 0) {
			const processedData = rawData.map((record) => {
				let processedRecord = {}
				Object.keys(CONST_MONGO_DB_FIELDS).forEach((field) => {
					if (field === 'eventType') {
						const matchingColorObj = eventTypeColors.find(
							(item) =>
								item.dataKey === CONST_ROMAN_KPI[record[field]].chartName,
						)
						processedRecord[CONST_MONGO_DB_FIELDS[field].name] =
							matchingColorObj ? matchingColorObj.dataKey : record[field]
					} else {
						processedRecord[CONST_MONGO_DB_FIELDS[field].name] =
							record[field] || ''
					}
				})
				return processedRecord
			})

			setTableData(processedData)
		}
	}, [rawData])

	useEffect(() => {
		console.log(rawData)
		return () => {}
	}, [rawData])

	return (
		<div className='flex flex-col space-y-2 mt-3 '>
			<div className='flex justify-between text-sm font-bold'>
				{Object.values(CONST_MONGO_DB_FIELDS).map((field) => (
					<div key={field.name} className='flex-auto'>
						{field.name}
					</div>
				))}
			</div>
			<div className='max-h-96 overflow-scroll'>
				{' '}
				{tableData.map((row, rowIndex) => (
					<div key={rowIndex} className='flex justify-between text-sm '>
						{Object.values(CONST_MONGO_DB_FIELDS).map((field) => (
							<div key={field.name} className='flex-auto'>
								{row[field.name]}
							</div>
						))}
					</div>
				))}
			</div>
		</div>
	)
}

export default BottomTable

// REFERENCE :: FULL rawData[] properties below
// appointmentScheduledBy : ""
// auditVerifiedBy : ""
// collectionsRep : ""
// contactCellPhone : ""
// contactEmail : "<ContactEmail>"
// contactFullName : "Gary"
// contactHomePhone : "(941) 354-0254"
// contactWorkPhone : ""
// createdAt : "2023-10-18T22:29:49.152Z"
// date : "10/18/2023"
// estimatedCost : "<TotalLine:CalculatedTotal:Sale,Cost>"
// estimatedMaterials : "<TotalLine:Materials:Sale,Cost>"
// eventContact : "Valen Tenney"
// eventType : "digitalLeadGenerated"
// initialAuditCompletedBy : ""
// installCrew : ""
// jobCosts : "$0.00"
// jobID : "23-07103"
// jobType : ""
// leadSource : "Ashbourne Global"
// leadType : "Digital Leads"
// payments : "$0.00"
// phase : "Ashbourne Global"
// roadMapUrl : "https://mygiddyup.com/Api/Geocode/MapByWork?WorkId=0bf26db3-f79a-432c-b227-9c371af036bd&MapType=1"
// salesRep : ""
// siteAddress1 : ""
// siteAddress2 : ""
// siteCity : ""
// siteState : ""
// siteZip : ""
// squares : ""
// supervisor : ""
// tearOffCrew : ""
// template : "Inbound Digital Lead Triage"
// timeStamp : "2023-10-18T22:29:47.588Z"
// totalContractPrice : "$0.00"
// updatedAt : "2023-10-18T22:29:49.152Z"
