export const KPI_CREATE_REQUEST = 'KPI_CREATE_REQUEST'
export const KPI_CREATE_SUCCESS = 'KPI_CREATE_SUCCESS'
export const KPI_CREATE_FAIL = 'KPI_CREATE_FAIL'
export const KPI_CREATE_RESET = 'KPI_CREATE_RESET'

export const KPI_DETAILS_REQUEST = 'KPI_DETAILS_REQUEST'
export const KPI_DETAILS_SUCCESS = 'KPI_DETAILS_SUCCESS'
export const KPI_DETAILS_FAIL = 'KPI_DETAILS_FAIL'

export const KPI_LIST_USER_REQUEST = 'KPI_LIST_USER_REQUEST'
export const KPI_LIST_USER_SUCCESS = 'KPI_LIST_USER_SUCCESS'
export const KPI_LIST_USER_FAIL = 'KPI_LIST_USER_FAIL'
export const KPI_LIST_USER_RESET = 'KPI_LIST_USER_RESET'

export const KPI_LIST_DATESTRING_REQUEST = 'KPI_LIST_DATESTRING_REQUEST'
export const KPI_LIST_DATESTRING_SUCCESS = 'KPI_LIST_DATESTRING_SUCCESS'
export const KPI_LIST_DATESTRING_FAIL = 'KPI_LIST_DATESTRING_FAIL'
export const KPI_LIST_DATESTRING_RESET = 'KPI_LIST_DATESTRING_RESET'

export const KPI_LIST_ALL_REQUEST = 'KPI_LIST_ALL_REQUEST'
export const KPI_LIST_ALL_SUCCESS = 'KPI_LIST_ALL_SUCCESS'
export const KPI_LIST_ALL_FAIL = 'KPI_LIST_ALL_FAIL'
