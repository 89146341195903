// register.js
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Header2, LandingPageLinks, Loader, Message } from '../../components'
import { login } from '../../redux/actions/userActions'

// import { CSS_THEME_ROUNDING } from '../../../css/constants'
import { useStateContext } from '../../contexts/ContextProvider'
import { Link } from 'react-router-dom'
import { CONST_DARK } from '../../constants/general'

const Register = (props) => {
	const { category, userName, onSubmit } = props
	//const [user, setUser] = useState(userName ? userName : '')

	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [phoneNumber, setPhoneNumber] = useState('')
	const [subscribe, setSubscribe] = useState(false)
	const [licenseKey, setLicenseKey] = useState('')
	const [error, setError] = useState(null)

	const { currentMode: mode } = useStateContext()

	// const dispatch = useDispatch()

	// const userLogin = useSelector((state) => state.userLogin)
	// const { loading, error, userInfo } = userLogin

	// const redirect = location.search ? location.search.split('=')[1] : HOME_ROUTE

	// useEffect(() => {
	//     if (userInfo) {
	//       history.push(redirect)
	//     }
	//   }, [history, userInfo, redirect])

	const changeEmailHandler = (e) => {
		setEmail(e.target.value)
		setError(null)
	}
	const changePasswordHandler = (e) => {
		setPassword(e.target.value)
		setError(null)
	}
	const changeLicenseKeyHandler = (e) => {
		setLicenseKey(e.target.value)
		setError(null)
	}
	const changeSubscribeHandler = (e) => {
		setSubscribe((prev) => !prev)
		setError(null)
	}
	const changePhoneNumberHandler = (e) => {
		// Strip all non-numeric characters from the input
		const cleaned = e.target.value.replace(/\D+/g, '')

		// Break down the string into parts and format it
		let formatted = ''

		if (cleaned.length > 0) {
			const part1 = cleaned.substring(0, 3)
			const part2 = cleaned.substring(3, 6)
			const part3 = cleaned.substring(6, 10)

			// Combine the parts with the desired format
			formatted = `(${part1})`
			if (part2) formatted += ` - ${part2}`
			if (part3) formatted += ` - ${part3}`
		}

		// Update the phone number with the formatted value
		setPhoneNumber(formatted)
		setError(null)
	}
	// const closeHandler = () => console.log('close')

	const submitHandler = (e) => {
		e.preventDefault()
		setError('Invalid Key.')
	}

	return (
		<div className='flex items-center justify-center dark:bg-romanDark1  min-h-screen'>
			{/* <!-- Card Container --> */}
			<div className='relative flex flex-col m-6 space-y-10 bg-romanLight1 dark:bg-romanDark2 shadow-2xl md:overflow-hidden rounded-2xl md:flex-row md:space-y-0 md:m-0'>
				{/* <!-- Left Side --> */}
				<div className='p-6 md:p-20'>
					{/* <!-- Top Content --> */}
					<h2 className='font-mono mb-5 text-xl md:text-4xl font-bold text-romanLightTextActive dark:text-romanDarkTextActive'>
						Register
					</h2>
					<p className=' mb-4 font-sans font-light text-romanLightTextInactive dark:text-romanDarkTextInactive'>
						Register your account to view company metrics, department scorecards
						and to read recent announcements.
					</p>
					<form onSubmit={submitHandler}>
						<input
							type='email'
							name='email'
							value={email}
							required
							onChange={changeEmailHandler}
							placeholder='Enter your email address'
							className='w-full my-2 p-6 border border-romanLightBorder1 bg-romanLight4  outline-none   dark:text-romanDarkTextActive dark:border-romanDarkBorder1 dark:bg-romanDark4 rounded-md placeholder:font-sans placeholder:font-light'
						/>
						<input
							type='password'
							name='password'
							value={password}
							required
							onChange={changePasswordHandler}
							className='w-full my-2 p-6 border border-romanLightBorder1 bg-romanLight4  outline-none   dark:text-romanDarkTextActive dark:border-romanDarkBorder1 dark:bg-romanDark4 rounded-md placeholder:font-sans placeholder:font-light'
							placeholder='Enter your password'
						/>
						<input
							type='text'
							name='license'
							value={licenseKey}
							required
							onChange={changeLicenseKeyHandler}
							className='w-full my-2 p-6 border border-romanLightBorder1 bg-romanLight4  outline-none   dark:text-romanDarkTextActive dark:border-romanDarkBorder1 dark:bg-romanDark4 rounded-md placeholder:font-sans placeholder:font-light'
							placeholder='Enter the key provided in the sign-up email'
						/>
						<input
							type='tel'
							name='tel'
							value={phoneNumber}
							required
							onChange={changePhoneNumberHandler}
							className='w-full my-2 p-6 border border-romanLightBorder1 bg-romanLight4  outline-none   dark:text-romanDarkTextActive dark:border-romanDarkBorder1 dark:bg-romanDark4 rounded-md placeholder:font-sans placeholder:font-light'
							placeholder='Enter your phone number'
						/>

						{/* <!-- Middle Content --> */}
						<div className='flex flex-col items-center justify-between mt-6 space-y-6 md:flex-row md:space-y-0'>
							<div className='py-2 px-3 flex flex-col space-y-3 border-1 border-gray-300 rounded-lg'>
								<div className='flex space-x-2 font-thin text-romanLightTextActive dark:text-romanDarkTextInactive'>
									<input
										type='checkbox'
										id='subscribe'
										checked={subscribe}
										onChange={changeSubscribeHandler}
									/>
									<div>SMS Update Campaign</div>
								</div>
								<div className='max-w-sm'>
									<div className='text-sm text-justify font-thin text-romanLightTextActive dark:text-romanDarkTextInactive'>
										Zeidos uses SMS to communicate with employees. We would like
										to contact you via text message to provide key company
										updates. Message frequency varies each month. By checking
										this box and entering your number you are agreeing to
										receive messages from Zeidos. Opt-out at any time by
										replying STOP. You may also Opt-in by texting START to (844)
										727-0433.
									</div>
								</div>
							</div>
							<button className='w-full md:w-auto flex justify-center items-center p-6 space-x-4 font-sans font-bold  rounded-md shadow-lg px-9  shadow-romanLight4 dark:shadow-romanDark4 hover:bg-opacity-90  hover:shadow-lg border dark:border-romanDarkTextInactive outline-none transition hover:-translate-y-0.5 duration-150'>
								<span className='text-romanRed1 dark:text-romanGold1'>
									Register
								</span>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									className='w-7'
									viewBox='0 0 24 24'
									strokeWidth='1.5'
									stroke={mode === CONST_DARK ? '#dbb60e' : '#ad0101'}
									fill='none'
									strokeLinecap='round'
									strokeLinejoin='round'
								>
									<path stroke='none' d='M0 0h24v24H0z' fill='none' />
									<line x1='5' y1='12' x2='19' y2='12' />
									<line x1='13' y1='18' x2='19' y2='12' />
									<line x1='13' y1='6' x2='19' y2='12' />
								</svg>
							</button>
						</div>
						{error && (
							<div className='text-sm m-auto'>
								<Message variant='danger' title='Error'>
									{JSON.stringify(error)}
								</Message>
							</div>
						)}
					</form>

					{/* <!-- Border --> */}
					<div className='mt-12 border-b border-b-romanLightBorder1 bg-romanLight4  dark:border-b-romanDarkBorder1'></div>
				</div>
			</div>
		</div>
	)
}

export default Register
