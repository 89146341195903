// event-list.js
import React, { useState } from 'react'
import EventItem from './event-item'

const headerFields = [
	'Date',
	'Job ID',
	'Job Category',
	'Event',
	'Completed By',
	'Phase',
	'Squares',
	'Value',
	'Sales Rep',
]

const EventList = ({ events }) => {
	const [filter, setFilter] = useState({
		eventType: 'all',
		salesRep: 'all',
		minValue: '',
		maxValue: '',
	})

	if (events[0] === 'Error Loading Events') {
		return (
			<div className='flex justify-center text-center text-red-500 my-4'>
				Error Loading Events
			</div>
		)
	}

	const handleFilterChange = (e, field) => {
		setFilter({
			...filter,
			[field]: e.target.value,
		})
	}

	// Extracting all totalContractPrice and converting them to numbers
	const allPrices = events.map((event) =>
		event.totalContractPrice
			? parseFloat(event.totalContractPrice.replace(/[^0-9.-]+/g, '')).toFixed(
					2,
			  )
			: 0,
	)

	// Getting unique prices, sorting them in ascending order, and formatting with commas
	const uniquePrices = [...new Set(allPrices)]
		.sort((a, b) => a - b)
		.map((price) =>
			parseFloat(price).toLocaleString('en-US', {
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
			}),
		)

	const uniqueEventTypes = [...new Set(events.map((event) => event.eventType))]
	const uniqueSalesReps = [...new Set(events.map((event) => event.salesRep))]

	const filteredEvents = events
		.filter((event) => {
			const value = event.totalContractPrice
				? parseFloat(
						event.totalContractPrice.replace(/[^0-9.-]+/g, ''),
				  ).toFixed(2)
				: 0

			return (
				(filter.eventType === 'all' || event.eventType === filter.eventType) &&
				(filter.salesRep === 'all' || event.salesRep === filter.salesRep) &&
				(filter.minValue === '' || value >= filter.minValue) &&
				(filter.maxValue === '' || value <= filter.maxValue)
			)
		})
		.sort((a, b) => new Date(b.timeStamp) - new Date(a.timeStamp)) // sorting in descending order based on timeStamp

	const formatText = (camelBackString) => {
		// Use a regular expression to split the string by capital letters
		const words = camelBackString.split(/(?=[A-Z])/)

		// Capitalize the first letter of each word and join them with spaces
		const formattedString = words
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ')

		return formattedString
	}

	return (
		<div className='mt-10'>
			<div className='mb-4'>
				<select
					onChange={(e) => handleFilterChange(e, 'eventType')}
					className='mr-2'
				>
					<option value='all'>All Event Types</option>
					{uniqueEventTypes.map((type, index) => (
						<option key={index} value={type}>
							{type}
						</option>
					))}
				</select>
				<select onChange={(e) => handleFilterChange(e, 'salesRep')}>
					<option value='all'>All Sales Reps</option>
					{uniqueSalesReps.map((rep, index) => (
						<option key={index} value={rep}>
							{rep}
						</option>
					))}
				</select>
				<select
					value={filter.minValue}
					onChange={(e) => handleFilterChange(e, 'minValue')}
					className='mr-2'
				>
					<option value=''>Select Min Value</option>
					{uniquePrices.map((price, index) => (
						<option key={index} value={price}>
							{price}
						</option>
					))}
				</select>
				<select
					value={filter.maxValue}
					onChange={(e) => handleFilterChange(e, 'maxValue')}
				>
					<option value=''>Select Max Value</option>
					{uniquePrices.map((price, index) => (
						<option key={index} value={price}>
							{price}
						</option>
					))}
				</select>
			</div>
			<div className='grid grid-cols-9'>
				<div className='col-span-9 text-xl font-semibold mb-2'>Event List</div>
				{headerFields.map((field, idx) => (
					<div key={idx} className='col-span-1 text-xs font-bold'>
						{field}
					</div>
				))}

				<div className='col-span-9 text-xs grid grid-cols-9  max-h-[400px] overflow-y-auto'>
					{filteredEvents.map((event) => (
						<EventItem
							key={event._id}
							fields={[
								`${new Date(event.timeStamp).toLocaleDateString()} ${new Date(
									event.timeStamp,
								).toLocaleTimeString()}`,
								event.jobID,
								event.template,
								formatText(event.eventType),
								event.eventContact,
								event.phase,
								event.squares,
								event.totalContractPrice,
								event.salesRep,
							]}
						/>
					))}
				</div>
			</div>
		</div>
	)
}

export default EventList
