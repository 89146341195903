//customer-signup.js
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { GEN_CONST } from '../../../constants/general'

const CustomerSignup = () => {
	const [address, setAddress] = useState('')
	const [firstName, setFirstName] = useState('')
	const [phone, setPhone] = useState('')
	const [email, setEmail] = useState('')
	const location = useLocation()
	const navigate = useNavigate()

	// Grab parameters from URL
	useEffect(() => {
		console.log(location)

		if (location && location.search) {
			const params = new URLSearchParams(location.search)
			const urlFirstName = params.get(GEN_CONST.urlParameters.FNAME)
				? params.get(GEN_CONST.urlParameters.FNAME)
				: ''
			const urlPhone = params.get(GEN_CONST.urlParameters.PHONE)
				? params.get(GEN_CONST.urlParameters.PHONE)
				: ''
			const urlEmail = params.get(GEN_CONST.urlParameters.EMAIL)
				? params.get(GEN_CONST.urlParameters.EMAIL)
				: ''
			setFirstName(urlFirstName)
			setEmail(urlEmail)
			setPhone(urlPhone)
		}

		return () => {}
	}, [location])

	const handleSubmit = (event) => {
		event.preventDefault()
		// You can add the logic to handle the address submission here
		alert(`Address submitted: ${address}`)

		// HOW DO YOU SEND INFORMATION BACK TO GO HIGH LEVEL? name, phone, email, and address?

		window.top.location.href = 'https://www.amazingblu.com/thank-you'
	}

	return (
		<div className='max-w-lg mx-auto my-10 p-6 border-2 border-gray-200 rounded-lg shadow'>
			<h2 className='text-lg font-bold text-center mb-4'>
				{`Amazing, ${firstName}! You are one step closer to Freedom.`}
			</h2>
			<form onSubmit={handleSubmit}>
				<label
					htmlFor='address'
					className='block text-sm font-medium text-gray-700 mb-2'
				>
					What is the address of the pool needing service?
				</label>
				<input
					type='text'
					id='firstName'
					value={firstName}
					onChange={(e) => setFirstName(e.target.value)}
					className='shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md mb-4 p-2'
					placeholder='Enter first name here'
					required
				/>
				<input
					type='phone'
					id='phone'
					value={phone}
					onChange={(e) => setPhone(e.target.value)}
					className='shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md mb-4 p-2'
					placeholder='Enter phone here'
					required
				/>
				<input
					type='email'
					id='email'
					value={email}
					onChange={(e) => setEmail(e.target.value)}
					className='shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md mb-4 p-2'
					placeholder='Enter email here'
					required
				/>
				<input
					type='text'
					id='address'
					value={address}
					onChange={(e) => setAddress(e.target.value)}
					className='shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md mb-4 p-2'
					placeholder='Enter address here'
					required
				/>
				<button
					type='submit'
					className='w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'
				>
					SUBMIT
				</button>
			</form>
		</div>
	)
}

export default CustomerSignup
