import React from 'react'
import { Header2, HomePageLinks } from '../../components'
import DepartmentGrid from '../company-metrics/DepartmentGrid'

const DepartmentScorecards = () => {
	return (
		<div className='dark:bg-main-dark-bg  bg-main-bg dark:text-romanLight1 font-opensans mt-20 '>
			<Header2 links={<HomePageLinks />} />

			<DepartmentGrid />

			{/* <Footer2 /> */}
		</div>
	)
}

export default DepartmentScorecards
