import React, { createContext, useContext, useState } from 'react'
import { CONST_LOCAL_STORAGE } from '../constants'

const StateContext = createContext()

const initialState = {
	chat: false,
	cart: false,
	userProfile: false,
	notification: false,
	contextMenu: false,
	centerModal: false,
}

export const ContextProvider = ({ children }) => {
	const [screenSize, setScreenSize] = useState(undefined)
	const [currentEmbeddedNav, setCurrentEmbeddedNav] = useState([])
	// const [currentColor, setCurrentColor] = useState('#03C9D7');
	const [currentPalette, setCurrentPalette] = useState({
		color1: '#ae1f23',
		color2: '#d71a1f',
		color3: '#a70d14',
		color4: '#d2d3d5',
		color5: '#ce8654',
		color6: '#efd483',
	})
	const [currentMode, setCurrentMode] = useState('Light')
	const [themeSettings, setThemeSettings] = useState(false)
	const [activeMenu, setActiveMenu] = useState(false)
	const [isClicked, setIsClicked] = useState(initialState)
	const [lastEvent, setLastEvent] = useState(null)
	const [lastPayload, setLastPayload] = useState(null)

	const setMode = (mode) => {
		setCurrentMode(mode)
		setThemeSettings(false)
		localStorage.setItem(CONST_LOCAL_STORAGE.themeMode, mode)
	}

	const setPalette = (palette) => {
		setCurrentPalette(palette)
		setThemeSettings(false)
		localStorage.setItem('colorPalette', JSON.stringify(palette))
	}

	const setEmbeddedNav = (links) => setCurrentEmbeddedNav(links)

	// const setColor = (color) => {
	//   setCurrentColor(color);
	//   localStorage.setItem('colorMode', color);
	// };

	const handleClick = (clicked, e, payload) => {
		setIsClicked({ ...initialState, [clicked]: true })
		setLastEvent(e)
		setLastPayload(payload)
	}

	return (
		// eslint-disable-next-line react/jsx-no-constructed-context-values
		<StateContext.Provider
			value={{
				setEmbeddedNav,
				setCurrentEmbeddedNav,
				currentEmbeddedNav,
				setPalette,
				setCurrentPalette,
				currentPalette,
				currentMode,
				activeMenu,
				screenSize,
				setScreenSize,
				handleClick,
				isClicked,
				initialState,
				setIsClicked,
				setActiveMenu,
				setCurrentMode,
				setMode,
				themeSettings,
				setThemeSettings,
				lastEvent,
				lastPayload,
			}}
		>
			{children}
		</StateContext.Provider>
	)
}

export const useStateContext = () => useContext(StateContext)
