import { REDUX_CONST } from '../../constants'

export const getPhases = (state = { data: [] }, action) => {
	switch (action.type) {
		case REDUX_CONST.giddyUp.phaseConstants.getPhases.request:
			return { loading: true }
		case REDUX_CONST.giddyUp.phaseConstants.getPhases.success:
			return { data: action.payload }
		case REDUX_CONST.giddyUp.phaseConstants.getPhases.fail:
			return { error: action.payload }
		case REDUX_CONST.giddyUp.phaseConstants.getPhases.reset:
			return {}
		default:
			return state
	}
}
export const createPhase = (state = {}, action) => {
	switch (action.type) {
		case REDUX_CONST.giddyUp.phaseConstants.createPhase.request:
			return { loading: true }
		case REDUX_CONST.giddyUp.phaseConstants.createPhase.success:
			return { data: action.payload }
		case REDUX_CONST.giddyUp.phaseConstants.createPhase.fail:
			return { error: action.payload }
		case REDUX_CONST.giddyUp.phaseConstants.createPhase.reset:
			return {}
		default:
			return state
	}
}
export const deletePhase = (state = {}, action) => {
	switch (action.type) {
		case REDUX_CONST.giddyUp.phaseConstants.deletePhase.request:
			return { loading: true }
		case REDUX_CONST.giddyUp.phaseConstants.deletePhase.success:
			return { data: action.payload }
		case REDUX_CONST.giddyUp.phaseConstants.deletePhase.fail:
			return { error: action.payload }
		case REDUX_CONST.giddyUp.phaseConstants.deletePhase.reset:
			return {}

		default:
			return state
	}
}
export const updatePhase = (state = {}, action) => {
	switch (action.type) {
		case REDUX_CONST.giddyUp.phaseConstants.updatePhase.request:
			return { loading: true }
		case REDUX_CONST.giddyUp.phaseConstants.updatePhase.success:
			return { data: action.payload }
		case REDUX_CONST.giddyUp.phaseConstants.updatePhase.fail:
			return { error: action.payload }
		case REDUX_CONST.giddyUp.phaseConstants.updatePhase.reset:
			return {}
		default:
			return state
	}
}
