//page-wrapper.js
import React from 'react'
import {
	Header2,
	HomePageLinks,
	PulseReportLinks,
	Sidebar,
} from '../../components'

const PageWrapper = ({ children }) => {
	return (
		<div className='dark:bg-main-dark-bg  bg-main-bg dark:text-white font-opensans mt-20'>
			<Sidebar title={'Dashboards'} links={<PulseReportLinks />} />
			<Header2 links={<HomePageLinks />} />
			<div className='max-w-6xl mx-auto'>{children}</div>
		</div>
	)
}

export default PageWrapper
