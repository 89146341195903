//marketing-content.js
import React from 'react'
import PageWrapper from '../page-wrapper'
import SummaryCard from './components/top-left'
import ShortTrend from './components/top-right'
import LongTrend from './components/bottom'
import { CONST_ROMAN_KPI } from '../../../constants/api'

// Filtering the CONST_ROMAN_KPI to include only those objects where department is 'Marketing'
const eventTypes = Object.values(CONST_ROMAN_KPI)
	.filter((kpi) => kpi.department === 'Marketing')
	.map((kpi) => kpi.eventType)

const MarketingContent = () => {
	return (
		<PageWrapper>
			<div className='grid gap-6 grid-cols-1 md:grid-cols-4 py-2 text-justify text-romanDarkTextInactive dark:text-romanDarkTextActive'>
				<SummaryCard eventTypes={eventTypes} />
				<ShortTrend eventTypes={eventTypes} />
				<LongTrend eventTypes={eventTypes} />
			</div>
		</PageWrapper>
	)
}

export default MarketingContent
