//store.js
import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import {
	projectListReducer,
	projectDetailsReducer,
	projectDeleteReducer,
	projectCreateReducer,
	projectUpdateReducer,
	// projectReviewCreateReducer,
	// projectTopRatedReducer,
} from './reducers/projectReducers'
import { cartReducer } from './reducers/cartReducers'

import {
	orderCreateReducer,
	orderDetailsReducer,
	orderPayReducer,
	orderDeliverReducer,
	orderListMyReducer,
	orderListReducer,
} from './reducers/orderReducers'

import {
	kpiCreateReducer,
	kpiDetailsReducer,
	kpiListUserReducer,
	kpiListDateStringReducer,
	kpiListAllReducer,
} from './reducers/kpiReducers'

import {
	getEvents,
	getLeads,
	getSales,
	getJobs,
	getPhases,
	createPhase,
	updatePhase,
	deletePhase,
	getDepartments,
	createDepartment,
	updateDepartment,
	deleteDepartment,
	payGradeCreateReducer,
	payGradeUpdateReducer,
	payGradeDetailsReducer,
	payGradeDeleteReducer,
	payGradeListAllReducer,
	levelCreateReducer,
	levelUpdateReducer,
	levelDetailsReducer,
	levelDeleteReducer,
	levelListAllReducer,
	loginUser,
	createUser,
	getUserDetails,
	updateUserProfile,
	getUsers,
	deleteUser,
	updateUser,
} from './reducers/index'

const reducer = combineReducers({
	sales: getSales,
	leads: getLeads,
	events: getEvents,
	jobs: getJobs,
	levelCreate: levelCreateReducer,
	levelDelete: levelDeleteReducer,
	levelDetails: levelDetailsReducer,
	levelList: levelListAllReducer,
	levelUpdate: levelUpdateReducer,
	payGradeCreate: payGradeCreateReducer,
	payGradeDelete: payGradeDeleteReducer,
	payGradeDetails: payGradeDetailsReducer,
	payGradeList: payGradeListAllReducer,
	payGradeUpdate: payGradeUpdateReducer,
	kpiCreate: kpiCreateReducer,
	kpiDetails: kpiDetailsReducer,
	kpiListUser: kpiListUserReducer,
	kpiList: kpiListAllReducer,
	kpiListDateString: kpiListDateStringReducer,
	projectList: projectListReducer,
	projectDetails: projectDetailsReducer,
	projectDelete: projectDeleteReducer,
	projectCreate: projectCreateReducer,
	projectUpdate: projectUpdateReducer,
	// projectReviewCreate: projectReviewCreateReducer,
	// projectTopRated: projectTopRatedReducer,
	cart: cartReducer,
	userLogin: loginUser,
	createUser,
	userDetails: getUserDetails,
	updateUserProfile,
	users: getUsers,
	phases: getPhases,
	createPhase,
	updatePhase,
	deletePhase,
	departments: getDepartments,
	createDepartment,
	updateDepartment,
	deleteDepartment,
	deleteUser,
	updateUser,
	orderCreate: orderCreateReducer,
	orderDetails: orderDetailsReducer,
	orderPay: orderPayReducer,
	orderDeliver: orderDeliverReducer,
	orderListMy: orderListMyReducer,
	orderList: orderListReducer,
})

const cartItemsFromStorage = localStorage.getItem('cartItems')
	? JSON.parse(localStorage.getItem('cartItems'))
	: []

const userInfoFromStorage = localStorage.getItem('userInfo')
	? JSON.parse(localStorage.getItem('userInfo'))
	: null

const shippingAddressFromStorage = localStorage.getItem('shippingAddress')
	? JSON.parse(localStorage.getItem('shippingAddress'))
	: {}

const initialState = {
	cart: {
		cartItems: cartItemsFromStorage,
		shippingAddress: shippingAddressFromStorage,
	},
	userLogin: { userInfo: userInfoFromStorage },
}

const middleware = [thunk]

const store = createStore(
	reducer,
	initialState,
	composeWithDevTools(applyMiddleware(...middleware)),
)

export default store
