export const LEVEL_CREATE_REQUEST = 'LEVEL_CREATE_REQUEST'
export const LEVEL_CREATE_SUCCESS = 'LEVEL_CREATE_SUCCESS'
export const LEVEL_CREATE_FAIL = 'LEVEL_CREATE_FAIL'
export const LEVEL_CREATE_RESET = 'LEVEL_CREATE_RESET'

export const LEVEL_DETAILS_REQUEST = 'LEVEL_DETAILS_REQUEST'
export const LEVEL_DETAILS_SUCCESS = 'LEVEL_DETAILS_SUCCESS'
export const LEVEL_DETAILS_FAIL = 'LEVEL_DETAILS_FAIL'

export const LEVEL_LIST_ALL_REQUEST = 'LEVEL_LIST_ALL_REQUEST'
export const LEVEL_LIST_ALL_SUCCESS = 'LEVEL_LIST_ALL_SUCCESS'
export const LEVEL_LIST_ALL_FAIL = 'LEVEL_LIST_ALL_FAIL'

export const LEVEL_UPDATE_REQUEST = 'LEVEL_UPDATE_REQUEST'
export const LEVEL_UPDATE_SUCCESS = 'LEVEL_UPDATE_SUCCESS'
export const LEVEL_UPDATE_FAIL = 'LEVEL_UPDATE_FAIL'
export const LEVEL_UPDATE_RESET = 'LEVEL_UPDATE_RESET'

export const LEVEL_DELETE_REQUEST = 'LEVEL_DELETE_REQUEST'
export const LEVEL_DELETE_SUCCESS = 'LEVEL_DELETE_SUCCESS'
export const LEVEL_DELETE_FAIL = 'LEVEL_DELETE_FAIL'
export const LEVEL_DELETE_RESET = 'LEVEL_DELETE_RESET'

export const levelConstants = {
	levelCreate: {
		request: LEVEL_CREATE_REQUEST,
		success: LEVEL_CREATE_SUCCESS,
		fail: LEVEL_CREATE_FAIL,
		reset: LEVEL_CREATE_RESET,
	},
	levelDelete: {
		request: LEVEL_DELETE_REQUEST,
		success: LEVEL_DELETE_SUCCESS,
		fail: LEVEL_DELETE_FAIL,
		reset: LEVEL_DELETE_RESET,
	},
	levelDetails: {
		request: LEVEL_DETAILS_REQUEST,
		success: LEVEL_DETAILS_SUCCESS,
		fail: LEVEL_DETAILS_FAIL,
	},
	levelList: {
		request: LEVEL_LIST_ALL_REQUEST,
		success: LEVEL_LIST_ALL_SUCCESS,
		fail: LEVEL_LIST_ALL_FAIL,
	},
	levelUpdate: {
		request: LEVEL_UPDATE_REQUEST,
		success: LEVEL_UPDATE_SUCCESS,
		fail: LEVEL_UPDATE_FAIL,
		reset: LEVEL_UPDATE_RESET,
	},
}
