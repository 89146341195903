import React from 'react'
import SummaryFinancials from './components/summary-financials'
import MonthlySales from './components/sales-month'
import { Header2, HomePageLinks } from '../../components'

const Executive = () => {
	return (
		<div className='dark:bg-main-dark-bg  bg-main-bg dark:text-white font-opensans mt-20'>
			<Header2 links={<HomePageLinks />} />

			<div className='dark:text-white text-xl md:text-3xl text-center justify-center items-center p-4'>
				Executive Report
			</div>
			<div className='flex flex-col lg:flex-row mx-auto text-xs'>
				<SummaryFinancials />
				<MonthlySales />
			</div>
		</div>
	)
}
export default Executive
