//chart-component.js
import React from 'react'
import {
	ResponsiveContainer,
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
} from 'recharts'
import { CONST_DATES } from '../../../constants/general'

const CustomTooltip = ({ active, payload, label }) => {
	if (active && payload && payload.length) {
		return (
			<div className='custom-tooltip bg-slate-200 dark:bg-slate-700 p-3'>
				<p className='label'>{`${label}: ${payload[0].value}`}</p>
			</div>
		)
	}
	return null
}

const ChartComponent = ({ data, title, onDateFilter, filter }) => {
	return (
		<div className='mb-10' style={{ width: '100%', height: 300 }}>
			<h2 className='text-lg  p-2'>{title}</h2>
			<div className='flex space-x-3 justify-center p-2'>
				<button
					className={`px-4 py-1 border-1 border-gray-300 rounded-full ${
						filter === CONST_DATES.today ? 'bg-gray-300' : ' '
					}  hover:bg-gray-300 hover:dark:bg-gray-600 dark:border-gray-600 `}
					onClick={() => onDateFilter(CONST_DATES.today)}
				>
					Today
				</button>
				<button
					className={`px-4 py-1 border-1 border-gray-300 rounded-full ${
						filter === CONST_DATES.thisWeek ? 'bg-gray-300' : ' '
					} hover:bg-gray-300 hover:dark:bg-gray-600 dark:border-gray-600 `}
					onClick={() => onDateFilter(CONST_DATES.thisWeek)}
				>
					This Week
				</button>
				<button
					className={`px-4 py-1 border-1 border-gray-300 rounded-full ${
						filter === CONST_DATES.thisMonth ? 'bg-gray-300' : ' '
					} hover:bg-gray-300 hover:dark:bg-gray-600 dark:border-gray-600 `}
					onClick={() => onDateFilter(CONST_DATES.thisMonth)}
				>
					This Month
				</button>
			</div>
			<ResponsiveContainer>
				<BarChart
					data={data}
					margin={{
						top: 5,
						right: 30,
						left: 20,
						bottom: 5,
					}}
				>
					<CartesianGrid strokeDasharray='3 3' />
					<XAxis dataKey='name' />
					<YAxis />
					<Tooltip content={<CustomTooltip />} />
					{/* Custom Tooltip is used here */}
					{/* <Legend /> */}
					<Bar dataKey='Count' fill='#a31e24' />
				</BarChart>
			</ResponsiveContainer>
		</div>
	)
}

export default ChartComponent
