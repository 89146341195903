//phaseActions.js
import axios from 'axios'
import { API_CONST } from '../../../constants/api'
import { REDUX_CONST } from '../../constants'
import { logout } from '../userActions'
export const resetPhaseItem = (key) => async (dispatch) => {
	switch (key) {
		case 'createPhase':
			dispatch({ type: REDUX_CONST.giddyUp.phaseConstants.createPhase.reset })

			break
		case 'updatePhase':
			dispatch({ type: REDUX_CONST.giddyUp.phaseConstants.updatePhase.reset })

			break
		case 'deletePhase':
			dispatch({ type: REDUX_CONST.giddyUp.phaseConstants.deletePhase.reset })

			break
		default:
			break
	}
}

export const getPhases = () => async (dispatch, getState) => {
	// console.log('Events:Request:GetEvents')
	try {
		dispatch({
			type: REDUX_CONST.giddyUp.phaseConstants.getPhases.request,
		})

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.get(
			API_CONST.channels.giddyUp + API_CONST.routes.giddyUp.phases.getPhases,
			config,
		)

		dispatch({
			type: REDUX_CONST.giddyUp.phaseConstants.getPhases.success,
			payload: data,
		})
	} catch (error) {
		dispatch({
			type: REDUX_CONST.giddyUp.phaseConstants.getPhases.fail,
			payload:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message,
		})
	}
}
export const createPhase = (phase) => async (dispatch, getState) => {
	const { phase: phaseName, group, department, order } = phase
	try {
		dispatch({
			type: REDUX_CONST.giddyUp.phaseConstants.createPhase.request,
		})

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.post(
			API_CONST.channels.giddyUp + API_CONST.routes.giddyUp.phases.getPhases,
			{ phase: phaseName, group, department, order },
			config,
		)

		dispatch({
			type: REDUX_CONST.giddyUp.phaseConstants.createPhase.success,
			payload: data,
		})

		// localStorage.setItem(CONST_LOCAL_STORAGE.userInfo, JSON.stringify(data))
	} catch (error) {
		dispatch({
			type: REDUX_CONST.giddyUp.phaseConstants.createPhase.fail,
			payload:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message,
		})
	}
}

export const deletePhase = (id) => async (dispatch, getState) => {
	// console.log(id)
	try {
		dispatch({
			type: REDUX_CONST.giddyUp.phaseConstants.deletePhase.request,
		})

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.delete(
			`${
				API_CONST.channels.giddyUp + API_CONST.routes.giddyUp.phases.getPhases
			}/${id}`,
			config,
		)

		dispatch({
			type: REDUX_CONST.giddyUp.phaseConstants.deletePhase.success,
			payload: data,
		})
	} catch (error) {
		console.log('error')
		console.log(error)
		const message =
			error.response && error.response.data.message
				? error.response.data.message
				: error.message
		if (message === 'Not authorized, token failed') {
			dispatch(logout())
		}
		dispatch({
			type: REDUX_CONST.giddyUp.phaseConstants.deletePhase.fail,
			payload: message,
		})
	}
}

export const updatePhase = (phase) => async (dispatch, getState) => {
	try {
		dispatch({
			type: REDUX_CONST.giddyUp.phaseConstants.updatePhase.request,
		})

		const {
			userLogin: { userInfo },
		} = getState()
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.put(
			`${
				API_CONST.channels.giddyUp + API_CONST.routes.giddyUp.phases.getPhases
			}/${phase._id}`,
			phase,
			config,
		)

		dispatch({
			type: REDUX_CONST.giddyUp.phaseConstants.updatePhase.success,
			payload: data,
		})
	} catch (error) {
		const message =
			error.response && error.response.data.message
				? error.response.data.message
				: error.message
		if (message === 'Not authorized, token failed') {
			dispatch(logout())
		}
		dispatch({
			type: REDUX_CONST.giddyUp.phaseConstants.updatePhase.fail,
			payload: message,
		})
	}
}
