import React, { useEffect } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { Footer, ThemeSettings } from './components'
import {
	PulseReport,
	PulseEvents,
	PulseMarketing,
	PulseCustomerCare,
	PulseSales,
	PulsePreProduction,
	PulseProduction,
	PulseCollections,
	Announcements,
	DepartmentScorecards,
	AdminScorecard,
	Login,
	Register,
	AdminHomepage2,
	AdminUsers,
	AdminDepartments,
	AdminPhases,
	Executive,
	CustomerSignup,
} from './pages/'
import { CONST_LOCAL_STORAGE, GREEN_PALETTE, ROUTES } from './constants/'
import './App.css'
import { useStateContext } from './contexts/ContextProvider'
import { CONST_LIGHT } from './constants/general'

const App = () => {
	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin

	const {
		setCurrentPalette,
		setCurrentMode,
		currentMode,
		activeMenu,
		themeSettings,
	} = useStateContext()

	useEffect(() => {
		const currentThemePalette = localStorage.getItem('colorPalette')
		const currentThemeMode = localStorage.getItem(CONST_LOCAL_STORAGE.themeMode)

		if (currentThemeMode && currentThemeMode !== 'undefined') {
			setCurrentMode(currentThemeMode)
		} else {
			setCurrentMode(CONST_LIGHT)
		}

		if (currentThemePalette && currentThemePalette !== 'undefined') {
			setCurrentPalette(JSON.parse(currentThemePalette))
		} else {
			setCurrentPalette(GREEN_PALETTE)
		}
	}, [setCurrentMode, setCurrentPalette])

	let routes = []

	if (userInfo) {
		routes = (
			<>
				<div
					className={'dark:bg-main-dark-bg  bg-main-bg min-h-screen w-full  '}
				>
					<div>
						{themeSettings && <ThemeSettings />}

						<Routes>
							<Route
								path={ROUTES.apps.bluCustomerSignup}
								element={<CustomerSignup />}
							/>
							{userInfo.isExecutive && (
								<Route
									path={ROUTES.pages.executive.home}
									element={<Executive />}
								/>
							)}
							<Route
								path={ROUTES.pages.announcements}
								element={<Announcements />}
							/>
							<Route
								path={ROUTES.pages.departmentScorecards.home}
								element={<DepartmentScorecards />}
							/>
							<Route
								path={ROUTES.pages.departmentScorecards.admin}
								element={<AdminScorecard />}
							/>
							<Route
								path={`${ROUTES.pages.pulseReport.home}${ROUTES.pages.pulseReport.events}`}
								element={<PulseEvents />}
							/>
							<Route
								path={`${ROUTES.pages.pulseReport.home}${ROUTES.pages.pulseReport.marketing}`}
								element={<PulseMarketing />}
							/>
							<Route
								path={`${ROUTES.pages.pulseReport.home}${ROUTES.pages.pulseReport.customerCare}`}
								element={<PulseCustomerCare />}
							/>
							<Route
								path={`${ROUTES.pages.pulseReport.home}${ROUTES.pages.pulseReport.sales}`}
								element={<PulseSales />}
							/>
							<Route
								path={`${ROUTES.pages.pulseReport.home}${ROUTES.pages.pulseReport.preProduction}`}
								element={<PulsePreProduction />}
							/>
							<Route
								path={`${ROUTES.pages.pulseReport.home}${ROUTES.pages.pulseReport.production}`}
								element={<PulseProduction />}
							/>
							<Route
								path={`${ROUTES.pages.pulseReport.home}${ROUTES.pages.pulseReport.collections}`}
								element={<PulseCollections />}
							/>
							<Route
								path={`${ROUTES.pages.pulseReport.home}`}
								element={<PulseReport />}
							/>

							<Route
								path={`${ROUTES.pages.admin.home}${ROUTES.pages.admin.users}`}
								element={<AdminUsers />}
							/>
							<Route
								path={`${ROUTES.pages.admin.home}${ROUTES.pages.admin.phases}`}
								element={<AdminPhases />}
							/>
							<Route
								path={`${ROUTES.pages.admin.home}${ROUTES.pages.admin.departments}`}
								element={<AdminDepartments />}
							/>

							<Route path={ROUTES.general.admin} element={<AdminHomepage2 />} />

							<Route path={ROUTES.general.home} element={<PulseReport />} />
							<Route path={ROUTES.general.login} element={<PulseReport />} />
							<Route
								path='*'
								element={<Navigate to={ROUTES.general.home} replace />}
							/>
						</Routes>
					</div>
					<Footer />
				</div>
			</>
		)
	} else {
		routes = (
			<>
				{/*  ------------------------------  SHOWN WITH USER/ROLE ACCESS ---------------------   */}
				<div
					className={
						activeMenu
							? 'dark:bg-main-dark-bg  bg-main-bg min-h-screen md:ml-72 w-full  '
							: 'bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 '
					}
				>
					<div>
						<Routes>
							<Route
								path={ROUTES.apps.bluCustomerSignup}
								element={<CustomerSignup />}
							/>
							<Route path={ROUTES.general.login} element={<Register />} />
							<Route path={ROUTES.general.home} element={<Register />} />
							<Route
								path='*'
								element={<Navigate to={ROUTES.general.home} replace />}
							/>
						</Routes>
					</div>
					<Footer />
				</div>
			</>
		)
	}

	return (
		<div className={currentMode === 'Dark' ? 'dark' : ''}>
			<div className='select-none flex relative dark:bg-main-dark-bg'>
				{routes}
			</div>
		</div>
	)
}

export default App
