import React from 'react'
import { rechartData } from '../../../data'
import { useStateContext } from '../../../contexts/ContextProvider'
import {
	CartesianGrid,
	Line,
	ResponsiveContainer,
	LineChart,
	XAxis,
} from 'recharts'

const Metric1 = () => {
	const { currentMode, currentPalette } = useStateContext()
	const { color1, color4, color5, color6 } = currentPalette
	return (
		<div className='relative p-10 rounded-xl bg-purple-700 md:col-span-2'>
			{/* <!-- Quotes Image --> */}
			<img
				src='images/bg-pattern-quotation.svg'
				alt=''
				className='absolute top-3 right-10 scale-125 md:top-7 md:right-24 md:scale-150'
			/>
			{/* <!-- Box Header --> */}
			<div className='flex z-10 space-x-4'>
				<div className='w-10 h-10 rounded-full ring-2 ring-purple-300'></div>
				<div className='text-sm'>
					<h4 className='opacity-90'>Top Left</h4>
					<p className='opacity-50'>SubTitle</p>
				</div>
			</div>

			{/* <!-- Large Text --> */}
			<p className='relative z-10 mt-6 text-xl'>TITLE</p>

			{/* <!-- Small Text --> */}
			<p className='mt-6 opacity-50 line-clamp-6'>TEXT</p>

			<ResponsiveContainer width='100%' height={200}>
				{/* // ACTIVE DOTS : activeDot={{ r: 8 }} */}
				<LineChart
					data={rechartData}
					margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
				>
					{[
						{
							name: '1',
							lineColor: color6,
							lineWidth: 3,
							type: 'monotone',
						},
						{
							name: '2',
							lineColor: color5,
							lineWidth: 3,
							type: 'monotone',
						},
						{
							name: '3',
							lineColor: color1,
							lineWidth: 3,
							type: 'monotone',
						},
					].map((line) => (
						<Line
							key={line.name}
							strokeWidth={line.lineWidth}
							type={line.type}
							dataKey={line.name}
							stroke={line.lineColor}
						/>
					))}
					<CartesianGrid stroke={'#ffffff'} strokeDasharray={'5 5'} />
					{/* <XAxis dataKey={'name'} /> */}
					{/* <YAxis /> */}
					{/* <Tooltip /> */}
				</LineChart>
			</ResponsiveContainer>
		</div>
	)
}

export default Metric1
