import React from 'react'
import PageWrapper from '../page-wrapper'
import MindMap from './MindMap/mind-map'

const PreProductionContent = () => {
	return (
		<PageWrapper>
			Mind Map Scratch Pad
			{/* <MindMap /> */}
		</PageWrapper>
	)
}

export default PreProductionContent
