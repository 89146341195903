//menu-payload
import React from 'react'
import { ICONS_DEFAULT } from '../../constants/icons'
import { CONST_ADD, CONST_DELETE, CONST_EDIT } from '../../constants/general'
import { Cell } from '../'

const MenuPayload = ({ onAction, item }) => {
	return (
		<div className='mt-1 text-xs text-gray-400'>
			<button
				className='flex w-full hover:bg-gray-50'
				onClick={(e) => onAction(e, CONST_EDIT, item)}
			>
				<Cell className='p-1 py-2 flex justify-start w-full'>
					<div className='pl-3 pt-[1px] text-sm'>{ICONS_DEFAULT.edit}</div>
					<div className='pl-3'>Edit</div>
				</Cell>
			</button>
			<button
				className='flex w-full hover:bg-gray-50'
				onClick={(e) => onAction(e, CONST_ADD, item)}
			>
				<Cell className='p-1 py-2 flex justify-start'>
					<div className='pl-3 pt-[1px] text-sm'>{ICONS_DEFAULT.addCircle}</div>
					<div className='pl-3'>Add</div>
				</Cell>
			</button>
			<button
				className='flex w-full hover:bg-gray-50'
				onClick={(e) => onAction(e, CONST_DELETE, item)}
			>
				<Cell className='p-1 py-2 flex justify-start'>
					<div className='pl-3 pt-[1px] text-sm'>
						{ICONS_DEFAULT.deleteTrash}
					</div>
					<div className='pl-3'>Delete</div>
				</Cell>
			</button>
		</div>
	)
}

export default MenuPayload
