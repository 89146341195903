// CONSTANTS
export const CONST_DARK = 'Dark'
export const CONST_LIGHT = 'Light'
export const CONST_START = 'Start'
export const CONST_STOP = 'Stop'
export const CONST_SUCCESS = 'Success'
export const CONST_FAIL = 'Fail'
export const CONST_ERROR = 'Error'
export const CONST_PREV = 'Previous'
export const CONST_NEXT = 'Next'
export const CONST_BUTTON = 'label'
export const CONST_LABEL = 'button'
export const CONST_EDIT = 'edit'
export const CONST_ADD = 'add'
export const CONST_DELETE = 'delete'

export const GEN_CONST = {
	dark: CONST_DARK,
	light: CONST_LIGHT,
	start: CONST_START,
	stop: CONST_STOP,
	success: CONST_SUCCESS,
	fail: CONST_FAIL,
	error: CONST_ERROR,
	previous: CONST_PREV,
	next: CONST_NEXT,
	label: CONST_LABEL,
	button: CONST_BUTTON,
	edit: CONST_EDIT,
	add: CONST_ADD,
	delete: CONST_DELETE,
	urlParameters: {
		FNAME: 'fn',
		EMAIL: 'em',
		PHONE: 'ph',
	},
}

export const CONST_DATES = {
	all: 'allDates',
	today: 'today',
	thisWeek: 'thisWeek',
	thisMonth: 'thisMonth',
	last7Days: 'last7Days',
	last30Days: 'last30Days',
	last90Days: 'last90Days',
	last180Days: 'last180Days',
	thisQuarter: 'thisQuarter',
	thisYear: 'thisYear',
	thisYearToDate: 'thisYearToDate',
	lastYear: 'lastYear',
	lastYearToDate: 'lastYearToDate',
	day: 'Day',
	week: 'Week',
	month: 'Month',
}

export const CONST_MONGO_DB_FIELDS = {
	createdAt: { alias: 'createdAt', name: 'Date' },
	jobID: { alias: 'jobID', name: 'Job #' },
	eventContact: { alias: 'eventContact', name: 'Created By' },
	eventType: { alias: 'eventType', name: 'Type' },
	leadSource: { alias: 'leadSource', name: 'Lead Source' },
	leadType: { alias: 'leadType', name: 'Lead Type' },
	jobType: { alias: 'jobType', name: 'Job Type' },
	phase: { alias: 'phase', name: 'Job Phase' },
	template: { alias: 'template', name: 'Template Used' },
}

export const CONST_COLORS = {
	darkBlue: 'hsl(217, 28%, 15%)',
	darkBlue1: 'hsl(218, 28%, 13%)',
	darkBlue2: 'hsl(216, 53%, 9%)',
	darkBlue3: 'hsl(219, 30%, 18%)',
	// romanRed1: 'hsl(176, 68%, 64%)',
	accentBlue: 'hsl(198, 60%, 50%)',
	lightRed: 'hsl(0, 100%, 63%)',
	cyan: 'hsl(180, 66%, 49%)',
	cyanLight: 'hsl(180, 66%, 69%)',
	darkViolet: 'hsl(257, 27%, 26%)',
	red: 'hsl(0, 87%, 67%)',
	grayishViolet: 'hsl(257, 7%, 63%)',
	veryDarkBlue: 'hsl(255, 11%, 22%)',
	veryDarkViolet: 'hsl(260, 8%, 14%)',
	romanRed1: 'hsl(0deg 99% 34%)',
	romanGold1: 'hsl(49deg 87% 46%)',
	romanGold2: 'hsl(50deg 86% 36%)',
	romanLight1: 'hsl(0deg 0% 100%)',
	romanLight2: 'hsl(360deg 0% 97%)',
	romanLight3: 'hsl(360deg 0% 92%)',
	romanLight4: 'hsl(360deg 0% 88%)',
	romanLightInactive: 'hsl(0deg 0% 38%)',
	romanLightBorder1: 'hsl(360deg 0% 90%)',
	romanLightTextInactive: 'hsl(0deg 0% 75%)',
	romanLightTextActive: 'hsl(0deg 0% 12%)',
	romanDark1: 'hsl(0deg 0% 9%)',
	romanDark2: 'hsl(0deg 0% 12%)',
	romanDark3: 'hsl(0deg 0% 16%)',
	romanDark4: 'hsl(0deg 0% 19%)',
	romanDarkInactive: 'hsl(0deg 0% 24%)',
	romanDarkBorder1: 'hsl(0deg 0% 28%)',
	romanDarkTextInactive: 'hsl(0deg 0% 49%)',
	romanDarkTextActive: 'hsl(0deg 0% 84%)',
}
