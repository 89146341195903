import React from 'react'
import PageWrapper from '../page-wrapper'

const ProductionContent = () => {
	return (
		<PageWrapper>
			{/* // Grid Container */}
			<div className='grid gap-6 grid-cols-1 md:grid-cols-4 py-2 text-justify text-gray-500'>
				{/* <!-- Box 1 --> */}
				<div className=' p-10 rounded-xl bg-slate-200 md:col-span-1'>
					{/* <!-- Box Header --> */}
					<div className='flex z-10 space-x-4'>
						<div className='w-10 h-10 rounded-full ring-2 ring-purple-300'></div>
						<div className='text-sm'>
							<h4 className='opacity-90'>Appointments Set</h4>
							<p className='opacity-50'>SubTitle</p>
						</div>
					</div>

					{/* <!-- Large Text --> */}
					<p className='relative z-10 mt-6 text-xl'>This week</p>

					{/* <!-- Small Text --> */}
					<p className='mt-6 opacity-50 line-clamp-6'>This Month</p>
				</div>

				{/* <!-- Box 2 --> */}
				<div className=' p-10 rounded-xl bg-slate-200 md:col-span-3'>
					{/* <!-- Box Header --> */}
					<div className='flex z-10 space-x-4'>
						<div className='w-10 h-10 rounded-full ring-2 ring-purple-300'></div>
						<div className='text-sm'>
							<h4 className='opacity-90'>Details</h4>
							<p className='opacity-50'>SubTitle</p>
						</div>
					</div>

					{/* <!-- Large Text --> */}
					<p className='relative z-10 mt-6 text-xl'>Content</p>

					{/* <!-- Small Text --> */}
					<p className='mt-6 opacity-50 line-clamp-6'>Content</p>
				</div>

				{/* <!-- Box 3 --> */}
				<div className=' p-10 rounded-xl bg-slate-200 md:col-span-4'>
					{/* <!-- Box Header --> */}
					<div className='flex z-10 space-x-4'>
						<div className='w-10 h-10 rounded-full ring-2 ring-purple-300'></div>
						<div className='text-sm'>
							<h4 className='opacity-90'>Line Chart</h4>
							<p className='opacity-50'>SubTitle</p>
						</div>
					</div>

					{/* <!-- Large Text --> */}
					<p className='relative z-10 mt-6 text-xl'>Content</p>

					{/* <!-- Small Text --> */}
					<p className='mt-6 opacity-50 line-clamp-6'>Content</p>
				</div>
			</div>
		</PageWrapper>
	)
}

export default ProductionContent
