import React from 'react'
import {
	PieChart,
	Pie,
	Sector,
	Cell,
	Legend,
	ResponsiveContainer,
} from 'recharts'
import { useStateContext } from '../../../contexts/ContextProvider'
import { rechartPieData } from '../../../data'

const Metric2 = () => {
	const { currentMode, currentPalette } = useStateContext()
	const { color1, color2, color3, color4, color5, color6 } = currentPalette

	return (
		<div className='p-10 rounded-xl bg-gray-600'>
			{/* <!-- Box Header --> */}
			<div className='flex space-x-4'>
				<div className='w-10 h-10 rounded-full ring-2 ring-purple-300'></div>
				<div className='text-sm'>
					<h4 className='opacity-90'>Top Middle</h4>
					<p className='opacity-50'>SubTitle</p>
				</div>
			</div>

			{/* <!-- Large Text --> */}
			<p className='mt-6 text-xl'>TITLE</p>

			{/* <!-- Small Text --> */}
			<p className='mt-6 opacity-50 line-clamp-5'>TEXT</p>

			<ResponsiveContainer width='100%' height={300}>
				<PieChart>
					{[
						{
							key: '1',
							data: rechartPieData[0],
							cx: '50%',
							cy: '50%',
							innerRadius: 40,
							outerRadius: 55,
							label: false,
							lineColor: color3,
							fill: color2,
						},
						{
							key: '2',
							data: rechartPieData[1],
							cx: '50%',
							cy: '50%',
							innerRadius: 60,
							outerRadius: 80,
							label: false,
							lineColor: color2,
							fill: color1,
						},
					].map((donut) => (
						<Pie
							key={donut.key}
							data={donut.data}
							dataKey={'value'}
							cx={donut.cx}
							cy={donut.cy}
							innerRadius={donut.innerRadius}
							outerRadius={donut.outerRadius}
							stroke={donut.lineColor}
							fill={donut.fill}
							label={donut.label}
						/>
					))}
				</PieChart>
			</ResponsiveContainer>
			<ResponsiveContainer width='100%' height={300}>
				<PieChart>
					{[
						{
							key: '1',
							data: rechartPieData[0],
							cx: '50%',
							cy: '50%',
							innerRadius: 40,
							outerRadius: 55,
							label: false,
							lineColor: color3,
							fill: color6,
						},
						{
							key: '2',
							data: rechartPieData[1],
							cx: '50%',
							cy: '50%',
							innerRadius: 60,
							outerRadius: 80,
							label: false,
							lineColor: color2,
							fill: color1,
						},
					].map((donut) => (
						<Pie
							key={donut.key}
							data={donut.data}
							dataKey={'value'}
							cx={donut.cx}
							cy={donut.cy}
							innerRadius={donut.innerRadius}
							outerRadius={donut.outerRadius}
							stroke={donut.lineColor}
							fill={donut.fill}
							label={donut.label}
						/>
					))}
				</PieChart>
			</ResponsiveContainer>
		</div>
	)
}

export default Metric2
