//throughput-trend-chart.js
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import {
	ResponsiveContainer,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ComposedChart,
} from 'recharts'
import { CONST_ROMAN_KPI } from '../../../../constants/api'
import { CONST_DATES } from '../../../../constants/general'

const CustomTooltip = ({ active, payload, label }) => {
	if (active && payload && payload.length) {
		const total = payload.reduce((acc, curr) => acc + curr.value, 0)
		return (
			<div className='outline-none p-2 bg-romanLight3 dark:bg-romanDark3 bg-opacity-50 dark:bg-opacity-50'>
				<p className='label'>{`Total on ${label} : ${total}`}</p>
				{payload.map((entry, index) => (
					<p
						style={{ color: entry.color }}
						key={`item-${index}`}
					>{`${entry.name} : ${entry.value}`}</p>
				))}
			</div>
		)
	}
	return null
}

const ThroughputTrendChart = ({ rawData, eventTypeColors }) => {
	const [chartData, setChartData] = useState([])
	const [timePeriod, setTimePeriod] = useState(CONST_DATES.day)

	const groupDataByTimePeriod = (data, period) => {
		return data.reduce((acc, curr) => {
			let dateKey

			switch (period) {
				case CONST_DATES.day:
					dateKey = moment(curr.date).format('YYYY-MM-DD')
					break
				case CONST_DATES.week:
					dateKey = moment(curr.date).startOf('week').format('YYYY-MM-DD')
					break
				case CONST_DATES.month:
					dateKey = moment(curr.date).startOf('month').format('YYYY-MM-DD')
					break
				default:
					throw new Error('Invalid time period')
			}

			if (!acc[dateKey]) {
				acc[dateKey] = {
					chartDay: dateKey,
					'Appointment Set': 0,
					'Contract Signed': 0,
					'Estimate Sent': 0,
					'Initial Appt Completed': 0,
					'Lead Contacted': 0,
					'Lead Emailed': 0,
					'Lead Phoned': 0,
					'Solar Job Sold': 0,
					'File Submitted To Audit': 0,
				}
			}

			const mappedEventType = CONST_ROMAN_KPI[curr.eventType].chartName
			acc[dateKey][mappedEventType] += 1

			return acc
		}, {})
	}

	useEffect(() => {
		if (rawData && rawData.length > 0) {
			const groupedData = groupDataByTimePeriod(rawData, timePeriod)
			setChartData(Object.values(groupedData))
		} else {
			setChartData([])
		}
	}, [rawData, timePeriod])

	return (
		<div style={{ width: '100%', height: 300 }} className='grow mt-10'>
			<div className='flex space-x-2 py-1 px-2 justify-end '>
				<button
					className={`rounded-full py-1 px-2 border-1 ${
						timePeriod === CONST_DATES.day
							? 'border-romanRed1'
							: 'border-romanLightTextInactive'
					}  ${
						timePeriod === CONST_DATES.day
							? 'dark:border-romanGold1'
							: 'dark:border-romanDarkBorder1'
					}`}
					onClick={() => setTimePeriod(CONST_DATES.day)}
				>
					Day
				</button>
				<button
					className={`rounded-full py-1 px-2 border-1 ${
						timePeriod === CONST_DATES.week
							? 'border-romanRed1'
							: 'border-romanLightTextInactive'
					}  ${
						timePeriod === CONST_DATES.week
							? 'dark:border-romanGold1'
							: 'dark:border-romanDarkBorder1'
					}`}
					onClick={() => setTimePeriod(CONST_DATES.week)}
				>
					Week
				</button>
				<button
					className={`rounded-full py-1 px-2 border-1 ${
						timePeriod === CONST_DATES.month
							? 'border-romanRed1'
							: 'border-romanLightTextInactive'
					}  ${
						timePeriod === CONST_DATES.month
							? 'dark:border-romanGold1'
							: 'dark:border-romanDarkBorder1'
					}`}
					onClick={() => setTimePeriod(CONST_DATES.month)}
				>
					Month
				</button>
			</div>
			<ResponsiveContainer>
				<ComposedChart
					data={chartData}
					margin={{
						top: 5,
						right: 30,
						left: 20,
						bottom: 5,
					}}
				>
					<CartesianGrid strokeDasharray='3 3' />
					<XAxis dataKey='chartDay' />
					<YAxis />
					<Tooltip content={<CustomTooltip />} />
					<Legend />
					{eventTypeColors.map((item) => (
						<Bar
							key={item.dataKey}
							dataKey={item.dataKey}
							stackId='a'
							fill={item.color}
						/>
					))}
				</ComposedChart>
			</ResponsiveContainer>
		</div>
	)
}

export default ThroughputTrendChart
