import React from 'react'
export const rechartData = [
	{
		name: 'Week 1',
		1: 4000,
		2: 2400,
		3: 2400,
	},
	{
		name: 'Week 2',
		1: 3000,
		2: 1398,
		3: 2210,
	},
	{
		name: 'Week 3',
		1: 2000,
		2: 9800,
		3: 2290,
	},
	{
		name: 'Week 4',
		1: 2780,
		2: 3908,
		3: 2000,
	},
	{
		name: 'Week 5',
		1: 1890,
		2: 4800,
		3: 2181,
	},
	{
		name: 'Week 6',
		1: 2390,
		2: 3800,
		3: 2500,
	},
	{
		name: 'Week 7',
		1: 3490,
		2: 4300,
		3: 2100,
	},
]

export const rechartPieData = [
	[
		{ name: 'Group A', value: 400 },
		{ name: 'Group B', value: 300 },
		{ name: 'Group C', value: 300 },
		{ name: 'Group D', value: 200 },
	],
	[
		{ name: 'Group A', value: 500 },
		{ name: 'Group B', value: 400 },
		{ name: 'Group C', value: 200 },
		{ name: 'Group D', value: 100 },
	],
]

export const rechartPieChartData1 = [
	{ name: 'Group A', value: 400 },
	{ name: 'Group B', value: 300 },
	{ name: 'Group C', value: 300 },
	{ name: 'Group D', value: 200 },
]
export const rechartPieChartData2 = [
	{ name: 'A1', value: 100 },
	{ name: 'A2', value: 300 },
	{ name: 'B1', value: 100 },
	{ name: 'B2', value: 80 },
	{ name: 'B3', value: 40 },
	{ name: 'B4', value: 30 },
	{ name: 'B5', value: 50 },
	{ name: 'C1', value: 100 },
	{ name: 'C2', value: 200 },
	{ name: 'D1', value: 150 },
	{ name: 'D2', value: 50 },
]

export const rechartLineBarAreaData = [
	{
		name: 'Jan',
		pySales: 1759052,
		goalSales: 2737319,
		grossMargin: 806956,
		actualSales: 2234244,
	},
	{
		name: 'Feb',
		pySales: 1827400,
		goalSales: 2843678,
		grossMargin: 1517985,
		actualSales: 2841147,
	},
	{
		name: 'Mar',
		pySales: 2594079,
		goalSales: 4036732,
		grossMargin: 1998407,
		actualSales: 3175967,
	},
	{
		name: 'Apr',
		pySales: 2112736,
		goalSales: 3287698,
		grossMargin: 1547471,
		actualSales: 3484944,
	},
	{
		name: 'May',
		pySales: 1955136,
		goalSales: 3042452,
		grossMargin: 1474082,
		actualSales: 4468640,
	},
	{
		name: 'Jun',
		pySales: 2309414,
		goalSales: 3593755,
		grossMargin: 123250,
		actualSales: 3228795,
	},
	{
		name: 'Jul',
		pySales: 2102099,
		goalSales: 3271146,
		grossMargin: -96331,
		actualSales: 3346607,
	},
	{
		name: 'Aug',
		pySales: 2160769,
		goalSales: 3362445,
		grossMargin: -1287301,
		actualSales: 4737683,
	},
	{
		name: 'Sep',
		pySales: 2011637,
		goalSales: 3130375,
		grossMargin: 258893,
		actualSales: 854492,
	},
	{
		name: 'Oct',
		pySales: 2295157,
		goalSales: 3571569,
		grossMargin: 0,
		actualSales: 0,
	},
	{
		name: 'Nov',
		pySales: 2884722,
		goalSales: 4489012,
		grossMargin: 0,
		actualSales: 0,
	},
	{
		name: 'Dec',
		pySales: 2977779,
		goalSales: 4633820,
		grossMargin: 0,
		actualSales: 0,
	},
]
