import React from 'react'

const Footer = () => (
	<div className='mt-80'>
		<p className='text-sm text-romanLightTextInactive dark:text-romanDarkTextInactive text-center m-20'>
			© 2023 All rights reserved | Powered by Zeidos.com
		</p>
	</div>
)

export default Footer
