//departmentConstants.js
export const DEPARTMENT_CREATE_REQUEST = 'DEPARTMENT_CREATE_REQUEST'
export const DEPARTMENT_CREATE_SUCCESS = 'DEPARTMENT_CREATE_SUCCESS'
export const DEPARTMENT_CREATE_FAIL = 'DEPARTMENT_CREATE_FAIL'
export const DEPARTMENT_CREATE_RESET = 'DEPARTMENT_CREATE_RESET'

export const DEPARTMENT_DELETE_REQUEST = 'DEPARTMENT_DELETE_REQUEST'
export const DEPARTMENT_DELETE_SUCCESS = 'DEPARTMENT_DELETE_SUCCESS'
export const DEPARTMENT_DELETE_FAIL = 'DEPARTMENT_DELETE_FAIL'
export const DEPARTMENT_DELETE_RESET = 'DEPARTMENT_DELETE_RESET'

export const DEPARTMENT_LIST_REQUEST = 'DEPARTMENT_LIST_REQUEST'
export const DEPARTMENT_LIST_SUCCESS = 'DEPARTMENT_LIST_SUCCESS'
export const DEPARTMENT_LIST_FAIL = 'DEPARTMENT_LIST_FAIL'
export const DEPARTMENT_LIST_RESET = 'DEPARTMENT_LIST_RESET'

export const DEPARTMENT_UPDATE_REQUEST = 'DEPARTMENT_UPDATE_REQUEST'
export const DEPARTMENT_UPDATE_SUCCESS = 'DEPARTMENT_UPDATE_SUCCESS'
export const DEPARTMENT_UPDATE_FAIL = 'DEPARTMENT_UPDATE_FAIL'
export const DEPARTMENT_UPDATE_RESET = 'DEPARTMENT_UPDATE_RESET'

export const departmentConstants = {
	getDepartments: {
		request: DEPARTMENT_LIST_REQUEST,
		success: DEPARTMENT_LIST_SUCCESS,
		fail: DEPARTMENT_LIST_FAIL,
		reset: DEPARTMENT_LIST_RESET,
	},
	updateDepartment: {
		request: DEPARTMENT_UPDATE_REQUEST,
		success: DEPARTMENT_UPDATE_SUCCESS,
		fail: DEPARTMENT_UPDATE_FAIL,
		reset: DEPARTMENT_UPDATE_RESET,
	},
	deleteDepartment: {
		request: DEPARTMENT_DELETE_REQUEST,
		success: DEPARTMENT_DELETE_SUCCESS,
		fail: DEPARTMENT_DELETE_FAIL,
		reset: DEPARTMENT_DELETE_RESET,
	},
	createDepartment: {
		request: DEPARTMENT_CREATE_REQUEST,
		success: DEPARTMENT_CREATE_SUCCESS,
		fail: DEPARTMENT_CREATE_FAIL,
		reset: DEPARTMENT_CREATE_RESET,
	},
}
