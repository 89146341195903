//summary-card.js
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getSales } from '../../../../redux/actions/giddyUpActions/eventActions'
import { CONST_DATES } from '../../../../constants/general'
import GridWrapper from '../../grid-wrapper'
import SummaryDonutChart from '../../marketing/components/summary-donut-chart'

const SummaryCard = ({ eventTypes }) => {
	const dispatch = useDispatch()
	const salesRdx = useSelector((state) => state.sales)

	const [topValue, setTopValue] = useState(' ')
	const [bottomValue, setBottomValue] = useState(' ')

	useEffect(() => {
		dispatch(
			getSales({
				eventTypes,
				timeRange: CONST_DATES.last7Days,
			}),
		)
		dispatch(
			getSales({
				eventTypes,
				timeRange: CONST_DATES.last30Days,
			}),
		)

		return () => {}
	}, [dispatch])

	useEffect(() => {
		// console.log(salesRdx.sales_last30Days)
		if (salesRdx.sales_last30Days && salesRdx.sales_last30Days.length > 0) {
			setBottomValue(salesRdx.sales_last30Days.length)
		} else {
			setBottomValue(' ')
		}

		return () => {}
	}, [salesRdx.sales_last30Days])
	useEffect(() => {
		// console.log(salesRdx.sales_last7Days)
		if (salesRdx.sales_last30Days && salesRdx.sales_last7Days.length > 0) {
			setTopValue(salesRdx.sales_last7Days.length)
		} else {
			setTopValue(' ')
		}

		return () => {}
	}, [salesRdx.sales_last7Days])
	return (
		<GridWrapper
			firstLine={'Appts Set'}
			secondLine={'TEXT'}
			colSpan={' md:col-span-1 '}
		>
			{/* <!-- Large Text --> */}
			<div className='flex justify-between'>
				<p className='relative z-10 mt-6 text-xl'>{`Previous 7 days: `}</p>
				<p className='relative z-10 mt-6 text-xl'>{topValue}</p>
			</div>
			{/* <!-- Small Text --> */}
			<div className='flex justify-between'>
				<p className='mt-6 opacity-50 line-clamp-6'>Previous 30 days:</p>
				<p className='mt-6 opacity-50 line-clamp-6 tex'>{bottomValue}</p>
			</div>

			<SummaryDonutChart
				data={[
					{ name: 'Last 7 days', value: topValue },
					{ name: 'Last 30 days', value: bottomValue },
				]}
			/>
		</GridWrapper>
	)
}

export default SummaryCard
