import React, { useState } from 'react'
import { Row } from '..'
import { ICONS_DEFAULT } from '../../constants/icons'

const Accordion = (props) => {
	const {
		title,
		children,
		contentClass,
		titleClass,
		titleMargin,
		iconClass,
		contentContainerClass,
		contentBackground,
	} = props
	const [showContent, setShowContent] = useState(false)

	return (
		<div
			className={
				showContent
					? `${contentContainerClass} ${contentBackground}`
					: contentContainerClass
			}
		>
			<Row className={`flex justify-start ${titleClass}`}>
				<button
					className={`flex justify-start ${titleClass}`}
					onClick={() => setShowContent(!showContent)}
				>
					<div className={iconClass}>
						{showContent === false
							? ICONS_DEFAULT.downChevron
							: ICONS_DEFAULT.minus}
					</div>
					<div className={titleMargin}>{title}</div>
				</button>
			</Row>
			{showContent && <Row addClassName={contentClass}>{children}</Row>}
		</div>
	)
}

Accordion.defaultProps = {
	titleMargin: 'ml-2',
	contentClass: 'ml-4 transition-all duration-300 ease-in',
}

export default Accordion
