import React from 'react'

const EventItem = (props) => {
	const {
		// timeStamp,
		// eventType,
		// template,
		// jobID,
		// date,
		// eventContact,
		// phase,
		// totalContractPrice,
		// jobType,
		// squares,
		// salesRep,
		// appointmentScheduledBy,
		// initialAuditCompletedBy,
		// auditVerifiedBy,
		// collectionsRep,
		// contactFullName,
		// contactEmail,
		// contactHomePhone,
		// contactCellPhone,
		// contactWorkPhone,
		// siteAddress1,
		// siteAddress2,
		// siteCity,
		// siteState,
		// siteZip,
		// jobCosts,
		// payments,
		// estimatedCost,
		// estimatedMaterials,
		fields,
	} = props
	return (
		<>
			{fields.map((field, idx) => (
				<div
					key={idx}
					className='col-span-1 border-b-1 border-romanLightBorder1 dark:border-romanDarkBorder1 px-4 py-2 '
				>
					{field}
				</div>
			))}
		</>
	)
}

export default EventItem
