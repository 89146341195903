//long-trend.js
import React, { useEffect, useState } from 'react'
import { getLeads } from '../../../../redux/actions'
import { useDispatch, useSelector } from 'react-redux'
import { CONST_COLORS, CONST_DATES } from '../../../../constants/general'
import { CONST_ROMAN_KPI } from '../../../../constants/api'
import LeadTable from './bottom-table'
import GridWrapper from '../../grid-wrapper'
import LeadChart from './bottom-chart'
const MenuContent = ({ onChangeRange, timeRange }) => {
	return (
		<div className='text-xs flex justify-evenly space-x-2 items-center'>
			<div
				onClick={() => onChangeRange(CONST_DATES.last7Days)}
				className={`px-2 py-1 border-1 ${
					timeRange.range === CONST_DATES.last7Days
						? 'border-romanRed1'
						: 'border-romanLightTextInactive'
				}  ${
					timeRange.range === CONST_DATES.last7Days
						? 'dark:border-romanGold1'
						: 'dark:border-romanDarkBorder1'
				}  rounded-full `}
			>
				7 days
			</div>
			<div
				onClick={() => onChangeRange(CONST_DATES.last30Days)}
				className={`px-2 py-1 border-1 ${
					timeRange.range === CONST_DATES.last30Days
						? 'border-romanRed1'
						: 'border-romanLightTextInactive'
				}  ${
					timeRange.range === CONST_DATES.last30Days
						? 'dark:border-romanGold1'
						: 'dark:border-romanDarkBorder1'
				}  rounded-full `}
			>
				30 days
			</div>
			<div
				onClick={() => onChangeRange(CONST_DATES.last90Days)}
				className={`px-2 py-1 border-1 ${
					timeRange.range === CONST_DATES.last90Days
						? 'border-romanRed1'
						: 'border-romanLightTextInactive'
				}  ${
					timeRange.range === CONST_DATES.last90Days
						? 'dark:border-romanGold1'
						: 'dark:border-romanDarkBorder1'
				}  rounded-full `}
			>
				90 days
			</div>
			<div
				onClick={() => onChangeRange(CONST_DATES.last180Days)}
				className={`px-2 py-1 border-1 ${
					timeRange.range === CONST_DATES.last180Days
						? 'border-romanRed1'
						: 'border-romanLightTextInactive'
				}  ${
					timeRange.range === CONST_DATES.last180Days
						? 'dark:border-romanGold1'
						: 'dark:border-romanDarkBorder1'
				}  rounded-full `}
			>
				180 days
			</div>
		</div>
	)
}
const eventTypeColors = [
	{
		dataKey: CONST_ROMAN_KPI.nonDigitalLeadCreated.chartName,
		color: CONST_COLORS.romanGold1,
	},
	{
		dataKey: CONST_ROMAN_KPI.digitalLeadGenerated.chartName,
		color: CONST_COLORS.romanRed1,
	},
	{
		dataKey: CONST_ROMAN_KPI.salesmanLeadCreated.chartName,
		color: CONST_COLORS.romanDarkTextInactive,
	},
]
const LongTrend = ({ eventTypes }) => {
	const dispatch = useDispatch()
	const leadsRdx = useSelector((state) => state.leads)

	const [timeRange, setTimeRange] = useState({
		range: CONST_DATES.last90Days,
		title: 'Previous 90 Days',
	})
	useEffect(() => {
		dispatch(
			getLeads({
				eventTypes,
				timeRange: timeRange.range,
			}),
		)

		return () => {}
	}, [dispatch, timeRange])

	const changeRangeHandler = (key) => {
		switch (key) {
			case CONST_DATES.last90Days:
				setTimeRange({
					range: CONST_DATES.last90Days,
					title: 'Previous 90 Days',
				})
				break
			case CONST_DATES.last30Days:
				setTimeRange({
					range: CONST_DATES.last30Days,
					title: 'Previous 30 Days',
				})
				break
			case CONST_DATES.last180Days:
				setTimeRange({
					range: CONST_DATES.last180Days,
					title: 'Previous 180 Days',
				})
				break
			case CONST_DATES.last7Days:
				setTimeRange({ range: CONST_DATES.last7Days, title: 'Previous 7 Days' })
				break

			default:
				break
		}
	}

	// Dynamically determine the data key based on the selected time range
	const leadsDataKey = `leads_${timeRange.range.replace('last', 'last')}`
	const leadsData = leadsRdx[leadsDataKey] || []

	return (
		<GridWrapper
			firstLine={'Lead Table'}
			secondLine={timeRange.title}
			colSpan={' md:col-span-4 '}
			rightContent={
				<MenuContent timeRange={timeRange} onChangeRange={changeRangeHandler} />
			}
		>
			<LeadChart />
			<LeadTable eventTypeColors={eventTypeColors} rawData={leadsData} />
		</GridWrapper>
	)
}

export default LongTrend
