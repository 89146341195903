//table-item.js
import React from 'react'
import { ICONS_DEFAULT } from '../../../constants/icons'

const TableItem = ({
	fields,
	className,
	wrapperClass,
	editField,
	draggable,
	onMouseEnter,
	onDragStart,
	onDragEnd,
	onDragEnter,
	draggableClassname,
	onClick,
}) => {
	if (!fields) {
		return <div>No Items</div>
	}

	return (
		<div
			draggable={draggable || false}
			onMouseEnter={onMouseEnter}
			onDragStart={onDragStart}
			onDragEnter={onDragEnter}
			onDragEnd={onDragEnd}
			className={wrapperClass}
		>
			{fields.map((field, idx, arr) => (
				<div key={idx} className={className}>
					<div
						className={draggable && idx === 0 ? ' pl-4 ' : ' '}
						onClick={idx !== arr.length - 1 ? onClick : undefined}
					>
						{field}
					</div>
					{draggable && idx === 0 && (
						<div className={draggableClassname}>{ICONS_DEFAULT.dragDots}</div>
					)}
				</div>
			))}
			{editField}
		</div>
	)
}

export default TableItem
