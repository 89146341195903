import React from 'react'
import PageWrapper from '../page-wrapper'
import LongTrend from './components/long-trend'
// import ShortTrend from './components/trend'
import ShortThroughputTrend from './components/throughput-trend'
import SummaryCard from './components/summary-appt-set'
import { CONST_ROMAN_KPI } from '../../../constants/api'

// Filtering the CONST_ROMAN_KPI to include only those objects where department is 'Sales'
const eventTypes = Object.values(CONST_ROMAN_KPI)
	.filter((kpi) => kpi.department === 'Sales')
	.map((kpi) => kpi.eventType)

const marketingEvents = Object.values(CONST_ROMAN_KPI)
	.filter((kpi) => kpi.department === 'Marketing')
	.map((kpi) => kpi.eventType)

const SalesContent = () => {
	return (
		<PageWrapper>
			<div className='grid gap-6 grid-cols-1 md:grid-cols-4 py-2 text-justify text-romanDarkTextInactive dark:text-romanDarkTextActive'>
				<SummaryCard eventTypes={eventTypes} />
				<ShortThroughputTrend
					eventTypes={eventTypes}
					marketingEvents={marketingEvents}
				/>
				{/* <ShortTrend eventTypes={eventTypes} /> */}

				<LongTrend eventTypes={eventTypes} />
			</div>
		</PageWrapper>
	)
}
export default SalesContent
