export const EVENTS_LIST_ALL_REQUEST = 'EVENTS_LIST_ALL_REQUEST'
export const EVENTS_LIST_ALL_SUCCESS = 'EVENTS_LIST_ALL_SUCCESS'
export const EVENTS_LIST_ALL_FAIL = 'EVENTS_LIST_ALL_FAIL'

export const GET_LEADS_REQUEST = 'GET_LEADS_REQUEST'
export const GET_LEADS_SUCCESS = 'GET_LEADS_SUCCESS'
export const GET_LEADS_FAIL = 'GET_LEADS_FAIL'
export const GET_SALES_REQUEST = 'GET_SALES_REQUEST'
export const GET_SALES_SUCCESS = 'GET_SALES_SUCCESS'
export const GET_SALES_FAIL = 'GET_SALES_FAIL'

export const eventConstants = {
	getEvents: {
		request: EVENTS_LIST_ALL_REQUEST,
		success: EVENTS_LIST_ALL_SUCCESS,
		fail: EVENTS_LIST_ALL_FAIL,
	},
	getLeads: {
		request: GET_LEADS_REQUEST,
		success: GET_LEADS_SUCCESS,
		fail: GET_LEADS_FAIL,
	},
	getSales: {
		request: GET_SALES_REQUEST,
		success: GET_SALES_SUCCESS,
		fail: GET_SALES_FAIL,
	},
}
