//sales-month.js
import React from 'react'
import {
	ResponsiveContainer,
	Bar,
	Line,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ComposedChart,
} from 'recharts'
import { CONST_COLORS } from '../../../constants/general'

const SalesMonth = () => {
	const data = [
		// Add your monthly data here
		{ month: 'Jan', sales: 4000, margin: 33, EBITDA: 17 },
		{ month: 'Feb', sales: 3000, margin: 35, EBITDA: 18 },
		{ month: 'Mar', sales: 2000, margin: 36, EBITDA: 19 },
		{ month: 'Apr', sales: 4000, margin: 33, EBITDA: 17 },
		{ month: 'May', sales: 3000, margin: 35, EBITDA: 18 },
		{ month: 'June', sales: 2000, margin: 36, EBITDA: 19 },
		{ month: 'July', sales: 4000, margin: 33, EBITDA: 17 },
		{ month: 'Aug', sales: 3000, margin: 35, EBITDA: 18 },
		{ month: 'Sep', sales: 2000, margin: 36, EBITDA: 19 },
		{ month: 'Oct', sales: 4000, margin: 33, EBITDA: 17 },
		{ month: 'Nov', sales: 3000, margin: 35, EBITDA: 18 },
		{ month: 'Dec', sales: 2000, margin: 36, EBITDA: 19 },
		// ... (add remaining months)
	]

	return (
		<div style={{ width: '100%', height: 300 }} className='grow mt-10'>
			<ResponsiveContainer>
				<ComposedChart
					data={data}
					margin={{
						top: 5,
						right: 30,
						left: 20,
						bottom: 5,
					}}
				>
					<CartesianGrid strokeDasharray='3 3' />
					<XAxis dataKey='month' />
					<YAxis yAxisId='left' />
					<YAxis yAxisId='right' orientation='right' domain={[0, 100]} />
					<Tooltip />
					<Legend />
					<Bar yAxisId='left' dataKey='sales' fill='#a31e24' />
					<Line
						yAxisId='right'
						type='monotone'
						dataKey='margin'
						stroke={CONST_COLORS.romanGold1}
						activeDot={{ r: 8 }}
					/>
					<Line
						yAxisId='right'
						type='monotone'
						dataKey='EBITDA'
						stroke={CONST_COLORS.romanGold1}
					/>
				</ComposedChart>
			</ResponsiveContainer>
		</div>
	)
}

export default SalesMonth
