import { hrConstants } from '../../constants/hrConstants'

export const levelCreateReducer = (state = {}, action) => {
	switch (action.type) {
		case hrConstants.levelConstants.levelCreate.request:
			return { loading: true }
		case hrConstants.levelConstants.levelCreate.success:
			return { loading: false, success: true, data: action.payload }
		case hrConstants.levelConstants.levelCreate.fail:
			return { loading: false, error: action.payload }
		case hrConstants.levelConstants.levelCreate.reset:
			return {}
		default:
			return state
	}
}
export const levelUpdateReducer = (state = {}, action) => {
	switch (action.type) {
		case hrConstants.levelConstants.levelUpdate.request:
			return { loading: true }
		case hrConstants.levelConstants.levelUpdate.success:
			return { loading: false, success: true, data: action.payload }
		case hrConstants.levelConstants.levelUpdate.fail:
			return { loading: false, error: action.payload }
		case hrConstants.levelConstants.levelUpdate.reset:
			return {}

		default:
			return state
	}
}

export const levelDetailsReducer = (
	state = { loading: true, data: [] },
	action,
) => {
	switch (action.type) {
		case hrConstants.levelConstants.levelDetails.request:
			return { loading: true }
		case hrConstants.levelConstants.levelDetails.success:
			return { loading: false, success: true, data: action.payload }
		case hrConstants.levelConstants.levelDetails.fail:
			return { loading: false, error: action.payload }
		default:
			return state
	}
}
export const levelDeleteReducer = (state = {}, action) => {
	switch (action.type) {
		case hrConstants.levelConstants.levelDelete.request:
			return { loading: true }
		case hrConstants.levelConstants.levelDelete.success:
			return { loading: false, success: true }
		case hrConstants.levelConstants.levelDelete.fail:
			return { loading: false, error: action.payload }
		case hrConstants.levelConstants.levelDelete.reset:
			return {}
		default:
			return state
	}
}

export const levelListAllReducer = (state = { data: [] }, action) => {
	switch (action.type) {
		case hrConstants.levelConstants.levelList.request:
			return { loading: true }
		case hrConstants.levelConstants.levelList.success:
			return { loading: false, success: true, data: action.payload }
		case hrConstants.levelConstants.levelList.fail:
			return { loading: false, error: action.payload }
		default:
			return state
	}
}
