import React from 'react'
import { Card, Backdrop } from '../'
import { useStateContext } from '../../contexts/ContextProvider'

const CenterModal = (props) => {
	const {
		width,
		children,
		category,
		title,
		titleClass,
		categoryClass,
		injectedButton,
		saveButton,
		saveButtonClass,
		headerClass,
	} = props

	const { setIsClicked, initialState } = useStateContext()

	const closeContextHandler = () => setIsClicked({ ...initialState })

	return (
		<>
			<Backdrop
				onClick={closeContextHandler}
				backgroundRGB={'rgba(0,0,0,.85)'}
			/>

			<Card
				width={width}
				category={category}
				title={title}
				categoryClasses={categoryClass}
				titleClasses={titleClass}
				injectedButton={injectedButton}
				headerClass={headerClass}
				className={
					'fixed z-50 top-[20%] left-0 lg:top-[30%] lg:left-[45%] border-1 border-romanLightBorder1 dark:border-romanDarkBorder1 shadow-md bg-romanLight1 dark:bg-romanDark1 dark:text-romanDarkTextActive text-romanLightTextActive  p-2 md:p-4 rounded-theme-rounding'
				}
			>
				{children}

				{saveButton && <div className={saveButtonClass}>{saveButton}</div>}
			</Card>
		</>
	)
}

export default CenterModal
