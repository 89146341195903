export const PHASE_CREATE_REQUEST = 'PHASE_CREATE_REQUEST'
export const PHASE_CREATE_SUCCESS = 'PHASE_CREATE_SUCCESS'
export const PHASE_CREATE_FAIL = 'PHASE_CREATE_FAIL'
export const PHASE_CREATE_RESET = 'PHASE_CREATE_RESET'

export const PHASE_DELETE_REQUEST = 'PHASE_DELETE_REQUEST'
export const PHASE_DELETE_SUCCESS = 'PHASE_DELETE_SUCCESS'
export const PHASE_DELETE_FAIL = 'PHASE_DELETE_FAIL'
export const PHASE_DELETE_RESET = 'PHASE_DELETE_RESET'

export const PHASE_LIST_REQUEST = 'PHASE_LIST_REQUEST'
export const PHASE_LIST_SUCCESS = 'PHASE_LIST_SUCCESS'
export const PHASE_LIST_FAIL = 'PHASE_LIST_FAIL'
export const PHASE_LIST_RESET = 'PHASE_LIST_RESET'

export const PHASE_UPDATE_REQUEST = 'PHASE_UPDATE_REQUEST'
export const PHASE_UPDATE_SUCCESS = 'PHASE_UPDATE_SUCCESS'
export const PHASE_UPDATE_FAIL = 'PHASE_UPDATE_FAIL'
export const PHASE_UPDATE_RESET = 'PHASE_UPDATE_RESET'

export const phaseConstants = {
	getPhases: {
		request: PHASE_LIST_REQUEST,
		success: PHASE_LIST_SUCCESS,
		fail: PHASE_LIST_FAIL,
		reset: PHASE_LIST_RESET,
	},

	updatePhase: {
		request: PHASE_UPDATE_REQUEST,
		success: PHASE_UPDATE_SUCCESS,
		fail: PHASE_UPDATE_FAIL,
		reset: PHASE_UPDATE_RESET,
	},

	deletePhase: {
		request: PHASE_DELETE_REQUEST,
		success: PHASE_DELETE_SUCCESS,
		fail: PHASE_DELETE_FAIL,
		reset: PHASE_DELETE_RESET,
	},
	createPhase: {
		request: PHASE_CREATE_REQUEST,
		success: PHASE_CREATE_SUCCESS,
		fail: PHASE_CREATE_FAIL,
		reset: PHASE_CREATE_RESET,
	},
}
