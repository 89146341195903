import { REDUX_CONST } from '../../constants'

export const getDepartments = (state = { data: [] }, action) => {
	switch (action.type) {
		case REDUX_CONST.departmentConstants.getDepartments.request:
			return { loading: true }
		case REDUX_CONST.departmentConstants.getDepartments.success:
			return { data: action.payload }
		case REDUX_CONST.departmentConstants.getDepartments.fail:
			return { error: action.payload }
		case REDUX_CONST.departmentConstants.getDepartments.reset:
			return {}
		default:
			return state
	}
}
export const createDepartment = (state = {}, action) => {
	switch (action.type) {
		case REDUX_CONST.departmentConstants.createDepartment.request:
			return { loading: true }
		case REDUX_CONST.departmentConstants.createDepartment.success:
			return { data: action.payload }
		case REDUX_CONST.departmentConstants.createDepartment.fail:
			return { error: action.payload }
		case REDUX_CONST.departmentConstants.createDepartment.reset:
			return {}
		default:
			return state
	}
}
export const deleteDepartment = (state = {}, action) => {
	switch (action.type) {
		case REDUX_CONST.departmentConstants.deleteDepartment.request:
			return { loading: true }
		case REDUX_CONST.departmentConstants.deleteDepartment.success:
			return { data: action.payload }
		case REDUX_CONST.departmentConstants.deleteDepartment.fail:
			return { error: action.payload }
		case REDUX_CONST.departmentConstants.deleteDepartment.reset:
			return {}

		default:
			return state
	}
}
export const updateDepartment = (state = {}, action) => {
	switch (action.type) {
		case REDUX_CONST.departmentConstants.updateDepartment.request:
			return { loading: true }
		case REDUX_CONST.departmentConstants.updateDepartment.success:
			return { data: action.payload }
		case REDUX_CONST.departmentConstants.updateDepartment.fail:
			return { error: action.payload }
		case REDUX_CONST.departmentConstants.updateDepartment.reset:
			return {}
		default:
			return state
	}
}
