//admin-homepage.js
import React from 'react'
import { Header2, HomePageLinks, AdminLinks, Sidebar } from '../../components'

const AdminHomepage = () => {
	return (
		<div className='dark:bg-main-dark-bg bg-main-bg dark:text-romanLight1 font-opensans mt-20'>
			<Sidebar title={'Admin Settings'} links={<AdminLinks />} />
			<Header2 links={<HomePageLinks />} />
			{/* Page Content */}
		</div>
	)
}

export default AdminHomepage
