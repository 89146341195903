//top-right-donut.js
import React, { useEffect, useState } from 'react'

import {
	PieChart,
	Pie,
	Cell,
	Tooltip,
	ResponsiveContainer,
	Legend,
} from 'recharts'
import { CONST_COLORS } from '../../../../constants/general'
import { CONST_ROMAN_KPI } from '../../../../constants/api'

const COLORS = [
	CONST_COLORS.romanGold1,
	CONST_COLORS.romanRed1,
	CONST_COLORS.romanDarkTextInactive,
]

const renderCustomizedLabel = ({
	cx,
	cy,
	midAngle,
	innerRadius,
	outerRadius,
	percent,
	index,
	payload,
}) => {
	const RADIAN = Math.PI / 180
	const radius = innerRadius + (outerRadius - innerRadius) * 0.5
	const x = cx + radius * Math.cos(-midAngle * RADIAN)
	const y = cy + radius * Math.sin(-midAngle * RADIAN)

	return (
		<text
			className='text-xs font-semibold'
			x={x}
			y={y}
			textAnchor='middle'
			dominantBaseline='central'
			fill='white'
		>
			{`${(percent * 100).toFixed(0)}%`}
		</text>
	)
}

const CustomTooltip = ({ active, payload }) => {
	if (active && payload && payload.length) {
		return (
			<div className='p-2 bg-romanLight3 dark:bg-romanDark3 bg-opacity-90 dark:bg-opacity-90'>
				{payload.map((entry, index) => (
					<p
						style={{ color: entry.color }}
						key={`item-${index}`}
					>{`${entry.name} : ${entry.value}`}</p>
				))}
			</div>
		)
	}
	return null
}

const SummaryDonutChart = ({ rawData, eventTypeColors }) => {
	const [chartData, setChartData] = useState([])
	const [totalLeads, setTotalLeads] = useState(0)

	const calculateTotalLeads = (data) => {
		const total = data.reduce((acc, item) => acc + item.value, 0)
		setTotalLeads(total)
	}

	useEffect(() => {
		if (rawData && rawData.length > 0) {
			const processedData = rawData.reduce((acc, curr) => {
				const mappedEventType = CONST_ROMAN_KPI[curr.eventType].chartName // Mapping eventType to chartName

				// Initialize the accumulator with the event types if not already present
				if (!acc[mappedEventType]) {
					acc[mappedEventType] = 0
				}

				// Sum up the values of each eventType
				acc[mappedEventType] += 1

				return acc
			}, {})

			// Convert the data into an array of objects that the PieChart component can use
			const donutChartData = Object.keys(processedData).map((key) => {
				return { name: key, value: processedData[key] }
			})

			// Set the donut chart data
			setChartData(donutChartData)

			// Calculate total leads
			calculateTotalLeads(donutChartData)
		} else {
			setChartData([])
			setTotalLeads(0)
		}
	}, [rawData])
	const renderLegend = (props) => {
		return (
			<div>
				<p>Total Leads: {totalLeads}</p>
				{chartData.map((entry, index) => (
					<p key={index} style={{ color: entry.color }}>
						{entry.name} : {entry.value}
					</p>
				))}
			</div>
		)
	}
	return (
		<ResponsiveContainer width='100%' height={300}>
			<PieChart>
				<Pie
					data={chartData}
					cx='50%'
					cy='50%'
					labelLine={false}
					label={renderCustomizedLabel}
					outerRadius={80}
					innerRadius={40}
					fill='#8884d8'
					dataKey='value'
				>
					{chartData.map((entry, index) => {
						const matchingColor = eventTypeColors.find(
							(item) => item.dataKey === entry.name,
						)
						return (
							<Cell
								key={`cell-${index}`}
								fill={
									matchingColor
										? matchingColor.color
										: COLORS[index % COLORS.length]
								}
							/>
						)
					})}
				</Pie>
				<Tooltip content={<CustomTooltip />} />

				<Legend content={renderLegend} />
			</PieChart>
		</ResponsiveContainer>
	)
}

export default SummaryDonutChart
