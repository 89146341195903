//grid-wrapper.js
import React from 'react'

const GridWrapper = ({
	children,
	firstLine,
	secondLine,
	colSpan,
	rightContent,
}) => {
	return (
		<div
			className={` p-10 rounded-xl bg-romanLight3 dark:bg-romanDark2 ${colSpan} `}
		>
			<div className='flex z-10 space-x-4 justify-between w-full'>
				<div className='text-sm'>
					<h4 className='opacity-90'>{firstLine}</h4>
					<p className='opacity-50'>{secondLine}</p>
				</div>
				{rightContent}
			</div>

			{children}
		</div>
	)
}

export default GridWrapper
