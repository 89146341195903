export const themePalettes = [
	{
		name: 'roman-theme',
		palette: {
			color1: '#ae1f23',
			color2: '#d71a1f',
			color3: '#a70d14',
			color4: '#d2d3d5',
			color5: '#ce8654',
			color6: '#efd483',
		},
	},
	{
		name: 'blue-theme',
		palette: {
			color1: '#1A97F5',
			color2: '#136baf',
			color3: '#115183',
			color4: '#0c3150',
			color5: '#191d28',
			color6: '#d2d3d5',
		},
	},
	{
		name: 'green-theme',
		palette: {
			color1: '#03C9D7',
			color2: '#0b9ba6',
			color3: '#0e636a',
			color4: '#0c3e43',
			color5: '#191d28',
			color6: '#d2d3d5',
		},
	},
	{
		name: 'purple-theme',
		palette: {
			color1: '#7352FF',
			color2: '#4d37af',
			color3: '#322472',
			color4: '#1e1544',
			color5: '#191d28',
			color6: '#d2d3d5',
		},
	},
	{
		name: 'red-theme',
		palette: {
			color1: '#FF5C8E',
			color2: '#a73d5f',
			color3: '#6b273d',
			color4: '#431926',
			color5: '#191d28',
			color6: '#d2d3d5',
		},
	},
	{
		name: 'indigo-theme',
		palette: {
			color1: '#1E4DB7',
			color2: '#173d92',
			color3: '#112c6a',
			color4: '#0c1d47',
			color5: '#191d28',
			color6: '#d2d3d5',
		},
	},
	{
		name: 'orange-theme',
		palette: {
			color1: '#FB9678',
			color2: '#b96f59',
			color3: '#7c4a3b',
			color4: '#482b22',
			color5: '#191d28',
			color6: '#d2d3d5',
		},
	},
]
