function Help() {
	return (
		<>
			<h1>Help</h1>
			<p>
				<a href='https://github.com/101samovar/react-mindmap.git'>
					Source code
				</a>
			</p>
			<p>
				<a href='https://youtube.com/c/Samovar101'>More video</a>
			</p>
		</>
	)
}

export default Help
