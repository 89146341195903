import React from 'react'
import AnnouncementItem from './AnnouncementItem'

const AnnouncementList = () => {
	return (
		<section
			id='shorten'
			className='relative bg-romanLight2 dark:bg-romanDark3 py-20'
		>
			{/* <!-- Shorten Container --> */}
			<div className='max-w-4xl mx-auto px-6 space-y-6'>
				<AnnouncementItem />
			</div>
		</section>
	)
}

export default AnnouncementList
