import axios from 'axios'
import { API_CONST } from '../../../constants/api'
import { REDUX_CONST } from '../../constants/'

export const getEvents =
	({ queryObject }) =>
	async (dispatch, getState) => {
		// console.log('Events:Request:GetEvents')
		try {
			dispatch({
				type: REDUX_CONST.giddyUp.eventConstants.getEvents.request,
			})

			const {
				userLogin: { userInfo },
			} = getState()

			const config = {
				headers: {
					Authorization: `Bearer ${userInfo.token}`,
				},
				params: queryObject,
			}

			const { data } = await axios.get(
				API_CONST.channels.giddyUp + API_CONST.routes.giddyUp.events.getEvents,
				config,
			)
			// console.log('Events:Response:GetEvents')
			// console.log(data)

			dispatch({
				type: REDUX_CONST.giddyUp.eventConstants.getEvents.success,
				payload: data,
			})
		} catch (error) {
			console.log(error)
			dispatch({
				type: REDUX_CONST.giddyUp.eventConstants.getEvents.fail,
				payload:
					error.response && error.response.data.message
						? error.response.data.message
						: error.message,
			})
		}
	}

export const getLeads =
	({ timeRange, eventTypes }) =>
	async (dispatch, getState) => {
		// console.log('Events:Request:GetEvents')
		try {
			dispatch({
				type: REDUX_CONST.giddyUp.eventConstants.getLeads.request,
			})

			const {
				userLogin: { userInfo },
			} = getState()

			const config = {
				headers: {
					Authorization: `Bearer ${userInfo.token}`,
				},
				params: { timeRange, eventTypes },
			}

			const { data } = await axios.get(
				API_CONST.channels.giddyUp + API_CONST.routes.giddyUp.events.getLeads,
				config,
			)
			// console.log('Events:Response:GetEvents')
			// console.log(data)

			dispatch({
				type: REDUX_CONST.giddyUp.eventConstants.getLeads.success,
				payload: data,
			})
		} catch (error) {
			console.log(error)
			dispatch({
				type: REDUX_CONST.giddyUp.eventConstants.getLeads.fail,
				payload:
					error.response && error.response.data.message
						? error.response.data.message
						: error.message,
			})
		}
	}
export const getSales =
	({ timeRange, eventTypes, phaseChanges }) =>
	async (dispatch, getState) => {
		// console.log('Events:Request:GetEvents')
		try {
			dispatch({
				type: REDUX_CONST.giddyUp.eventConstants.getSales.request,
			})

			const {
				userLogin: { userInfo },
			} = getState()

			const config = {
				headers: {
					Authorization: `Bearer ${userInfo.token}`,
				},
				params: { timeRange, eventTypes, phaseChanges },
			}

			const { data } = await axios.get(
				API_CONST.channels.giddyUp + API_CONST.routes.giddyUp.events.getSales,
				config,
			)
			// console.log('Events:Response:GetEvents')
			// console.log(data)

			dispatch({
				type: REDUX_CONST.giddyUp.eventConstants.getSales.success,
				payload: data,
			})
		} catch (error) {
			console.log(error)
			dispatch({
				type: REDUX_CONST.giddyUp.eventConstants.getSales.fail,
				payload:
					error.response && error.response.data.message
						? error.response.data.message
						: error.message,
			})
		}
	}
