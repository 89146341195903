import React from 'react'
import {
	BarChart,
	Bar,
	Cell,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
} from 'recharts'
import { rechartData } from '../../../data'

const Metric5 = () => {
	return (
		<div className='p-10 rounded-xl bg-gray-900 md:col-span-2'>
			{/* <!-- Box Header --> */}
			<div className='flex space-x-4'>
				<div className='w-10 h-10 rounded-full ring-2 ring-purple-300'></div>
				<div className='text-sm'>
					<h4 className='opacity-90'>Bottom Middle</h4>
					<p className='opacity-50'>SubTitle</p>
				</div>
			</div>

			{/* <!-- Large Text --> */}
			<p className='mt-6 text-xl'>TITLE</p>

			{/* <!-- Small Text --> */}
			<p className='mt-6 opacity-50'>TEXT</p>
			<ResponsiveContainer width='100%' height={200}>
				<BarChart
					width={800}
					height={420}
					data={rechartData}
					margin={{
						top: 5,
						right: 30,
						left: 20,
						bottom: 5,
					}}
				>
					<CartesianGrid stroke={'#ccc'} strokeDasharray={'3 3'} />
					<XAxis dataKey={'name'} />
					<YAxis />
					<Tooltip />
					<Legend />
					{[{ name: '1', color: '#82ca9d', type: 'monotone' }].map((bar) => (
						<Bar key={bar.name} dataKey={bar.name} fill={bar.color} />
					))}
				</BarChart>
			</ResponsiveContainer>
			<ResponsiveContainer width='100%' height={200}>
				<BarChart
					width={800}
					height={420}
					data={rechartData}
					margin={{
						top: 5,
						right: 30,
						left: 20,
						bottom: 5,
					}}
				>
					<CartesianGrid stroke={'#ccc'} strokeDasharray={'3 3'} />
					<XAxis dataKey={'name'} />
					<YAxis />
					<Tooltip />
					<Legend />
					{[
						{ name: '2', color: '#8884d8', type: 'monotone' },
						{ name: '3', color: '#33373E', type: 'monotone' },
					].map((bar) => (
						<Bar key={bar.name} dataKey={bar.name} fill={bar.color} />
					))}
				</BarChart>
			</ResponsiveContainer>
		</div>
	)
}

export default Metric5
