import React from 'react'
import LeadListDesktop from './LeadListDesktop'
import LeadListMobile from './LeadListMobile'
import Metric1 from './Metric1'
import Metric2 from './Metric2'
import Metric4 from './Metric4'
import Metric5 from './Metric5'

const MetricGrid = () => {
	return (
		// <!-- Global Container -->
		<div className='container mx-auto max-w-7xl p-2 md:p-10'>
			{/* <!-- Grid Container --> */}
			<div className='grid gap-6 grid-cols-1 text-white md:grid-cols-4 md:grid-rows-2'>
				{/* <!-- Box 1 --> */}
				<Metric1 />

				{/* <!-- Box 2 --> */}
				<Metric2 />

				{/* <!-- Box 3 --> */}
				<LeadListDesktop />

				{/* <!-- Box 4 --> */}
				<Metric4 />

				{/* <!-- Box 5 --> */}
				<Metric5 />

				{/* <!-- Box 6 --> */}
				<LeadListMobile />
			</div>
		</div>
	)
}

export default MetricGrid
